import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import quest_service from './quest_service'
import toast from 'react-hot-toast';

// Get Quest from localStorage
const quest = JSON.parse(localStorage.getItem('quest'))

const initialState = {
  newlyRegistered:{},
  updatedQuest:{},
  oneQuestCalled:{},
  fullQuestList:[],
  ourCompany:{},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Register quest
export const registerQuests = createAsyncThunk(
  'quest_auth/registerQuests',
  async (quest, thunkAPI) => {
    try {
      return await quest_service.registerQuests(quest)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Get All Quests 
export const getQuests = createAsyncThunk(
  'quest_auth/getAllQuests',
  async (quest_info, thunkAPI) => {
    try {
      console.log("Getting Quests")
      return await quest_service.getQuests(quest_info)
    } catch (error) {
      console.log("Can't Get Quests")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Get One quest
export const getOneQuest = createAsyncThunk(
  'quest_auth/getOneQuests',
  async (quest_info, thunkAPI) => {
    try {

      return await quest_service.getOneQuest(quest_info)
    } catch (error) {
      console.log("Can't Get Quests")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Update Quest 
export const updateQuestInfo = createAsyncThunk(
  'quest_auth/updateQuestInfo',
  async (quest_info, thunkAPI) => {
    try {

      return await quest_service.updateQuestInfo(quest_info)
    } catch (error) {
      console.log("Can't Update Quests")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Delete Quest 
export const deleteQuest = createAsyncThunk(
  'quest_auth/deleteQuest',
  async (quest_info, thunkAPI) => {
    try {

      return await quest_service.deleteQuest(quest_info)
    } catch (error) {
      console.log("Can't Delete quest")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const quest_slice = createSlice({
  name: 'quest_auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerQuests.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerQuests.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.newlyRegistered = action.payload
        toast.success("Registered Quest Successfully")
      })
      .addCase(registerQuests.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.newlyRegistered = null
        toast.error("Quest Registration Error")
      })


      .addCase(getQuests.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getQuests.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.fullQuestList = action.payload
      })
      .addCase(getQuests.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })


      .addCase(getOneQuest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOneQuest.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.oneQuestCalled = action.payload
        toast.success("Quest Info Ready")
      })
      .addCase(getOneQuest.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Getting Quest Info Error")
      })


      .addCase(updateQuestInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateQuestInfo.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.updatedQuest = action.payload
        const newFullList = fullQuestList;
        newFullList.map(comp => comp._id === updatedQuest._id ? updatedQuest : comp);
        state.fullQuestList = newFullList;
        toast.success("Quest Updated Successfully")
      })
      .addCase(updateQuestInfo.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Quest Update Error")
      })

      .addCase(deleteQuest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteQuest.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        toast.success("Quest Deleted Successfully")
      })
      .addCase(deleteQuest.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Quest Delete Error")
      })

    
    },
  })

export const { reset } = quest_slice.actions
export default quest_slice.reducer
