import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import user_service from './user_friends_service';
import toast from 'react-hot-toast';

// Get User from localStorage
const appVersion = '1.0.0'; // Your current app version

const initialState = {
  friends: [],
  blocked: [],
  searchResults:[],
  totalPages: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  appVersion, // Add app version to state
};


// Search Users
export const searchUsers = createAsyncThunk(
  'userFriends/searchUsers',
  async ({ searchTerm }, thunkAPI) => {
    try {
      return await user_service.searchUsers(searchTerm);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const addFriend = createAsyncThunk(
  'userFriends/addFriend',
  async ({ userId, friendId }, thunkAPI) => {
    try {
      return await user_service.addFriend(userId, friendId);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Remove Friend
export const removeFriend = createAsyncThunk(
  'userFriends/removeFriend',
  async ({ userId, friendId }, thunkAPI) => {
    try {
      return await user_service.removeFriend(userId, friendId);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Share Bucks with a Friend
export const shareBucks = createAsyncThunk(
  'userFriends/shareBucks',
  async ({ userId, friendId, amount }, thunkAPI) => {
    try {
      return await user_service.shareBucks(userId, friendId, amount);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Share Power Up with a Friend
export const sharePowerUp = createAsyncThunk(
  'userFriends/sharePowerUp',
  async ({ userId, friendId, powerUpId }, thunkAPI) => {
    try {
      return await user_service.sharePowerUp(userId, friendId, powerUpId);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Friends List
export const getFriendsList = createAsyncThunk(
  'userFriends/getFriendsList',
  async ({ userId, limit, skip }, thunkAPI) => {
    try {
      return await user_service.getFriendsList(userId, limit, skip);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const userFriendsSlice = createSlice({
  name: 'userFriends',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(searchUsers.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(searchUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.searchResults = action.payload;
    })
    .addCase(searchUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      toast.error('Error searching users');
    })
    
    .addCase(addFriend.fulfilled, (state, action) => {
      const { friendId } = action.meta.arg;

      // Update searchResults to reflect the new friendship
      state.searchResults = state.searchResults.map(user =>
        user._id === friendId ? { ...user, isFriend: true } : user
      );

      state.isLoading = false;
      state.isSuccess = true;
      toast.success('Friend added successfully');
    })
    .addCase(addFriend.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      toast.error('Error adding friend');
    })

      .addCase(removeFriend.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeFriend.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.friends = state.friends.filter(friend => friend.id !== action.payload.friendId);
        toast.success('Friend removed successfully');
      })
      .addCase(removeFriend.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error('Error removing friend');
      })
      .addCase(shareBucks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(shareBucks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success('Bucks shared successfully');
      })
      .addCase(shareBucks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error('Error sharing bucks');
      })
      .addCase(sharePowerUp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sharePowerUp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success('Power-up shared successfully');
      })
      .addCase(sharePowerUp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error('Error sharing power-up');
      })
      .addCase(getFriendsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFriendsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const newFriends = action.payload.filter(newFriend => 
          !state.friends.some(existingFriend => existingFriend._id === newFriend._id)
        );
        state.friends = [...state.friends, ...newFriends];
      })
      .addCase(getFriendsList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error('Error fetching friends list');
      });
  },
});

export const { reset } = userFriendsSlice.actions;
export default userFriendsSlice.reducer;
