import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import order_service from './order_service'
import toast from 'react-hot-toast';

// Get Order from localStorage
const order = JSON.parse(localStorage.getItem('order'))
const appVersion = '1.0.0' // Your current app version

const initialState = {
  allOrders: [],
  favoriteOrders: [],
  currentOrders: [],
  completedOrders: [],
  isError: false,
  isSuccess: false,
  isLoadingOrders: false,
  message: '',
  appVersion, // Add app version to state
}

// Register Order
export const registerOrder = createAsyncThunk(
  'order_auth/registerOrder',
  async (order, thunkAPI) => {
    try {
      return await order_service.registerOrder(order)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
export const fetchOrders = createAsyncThunk(
  'orders/fetchOrders',
  async ({ userId, year, month }, thunkAPI) => {
    try {
      const response = await order_service.getAllOrders(userId, year, month);
      console.log("Orders fetched from API:", response); // Add this log
      return response;
    } catch (error) {
      console.error("Error fetching orders:", error); // Add this log
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
// Get One Order
export const getOneOrder = createAsyncThunk(
  'order_auth/getOneCompanies',
  async (order_info, thunkAPI) => {
    try {

      return await order_service.getOneOrder(order_info)
    } catch (error) {
      console.log("Can't Get Companies")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Update Order 
export const updateOrderInfo = createAsyncThunk(
  'order_auth/updateOrderInfo',
  async (order_info, thunkAPI) => {
    try {

      return await order_service.updateOrderInfo(order_info)
    } catch (error) {
      console.log("Can't Update Companies")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Delete Order 
export const deleteOrder = createAsyncThunk(
  'order_auth/deleteOrder',
  async (order_info, thunkAPI) => {
    try {

      return await order_service.deleteOrder(order_info)
    } catch (error) {
      console.log("Can't Delete order")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const order_slice = createSlice({
  name: 'order_auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoadingOrders = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerOrder.pending, (state) => {
        state.isLoadingOrders = true
      })
      .addCase(registerOrder.fulfilled, (state, action) => {
        state.isLoadingOrders = false
        state.isSuccess = true
        state.newlyRegistered = action.payload
        toast.success("Registered Order Successfully")
      })
      .addCase(registerOrder.rejected, (state, action) => {
        state.isLoadingOrders = false
        state.isError = true
        state.message = action.payload
        state.newlyRegistered = null
        toast.error("Order Registration Error")
      })


      .addCase(fetchOrders.pending, (state) => {
        state.isLoadingOrders = true;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.isLoadingOrders = false;
        state.currentOrders = action.payload.activeOrders;
        state.completedOrders = action.payload.completedOrders;
        console.log("Current Orders:", state.currentOrders); // Log current orders
        console.log("Completed Orders:", state.completedOrders); // Log completed orders
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.isLoadingOrders = false;
        state.isError = true;
        state.message = action.payload;
        console.error("Error in fetchOrders:", action.payload); // Log error
      })


      .addCase(getOneOrder.pending, (state) => {
        state.isLoadingOrders = true
      })
      .addCase(getOneOrder.fulfilled, (state, action) => {
        state.isLoadingOrders = false
        state.isSuccess = true
        state.oneOrderCalled = action.payload
        toast.success("Order Info Ready")
      })
      .addCase(getOneOrder.rejected, (state, action) => {
        state.isLoadingOrders = false
        state.isError = true
        state.message = action.payload
        toast.error("Getting Order Info Error")
      })


      .addCase(updateOrderInfo.pending, (state) => {
        state.isLoadingOrders = true
      })
      .addCase(updateOrderInfo.fulfilled, (state, action) => {
        state.isLoadingOrders = false
        state.isSuccess = true
        state.updatedOrder = action.payload
        const newFullList = state.fullOrderList;
        newFullList.map(comp => comp._id === updatedOrder._id ? updatedOrder : comp);
        state.fullOrderList = newFullList;
        toast.success("Order Updated Successfully")
      })
      .addCase(updateOrderInfo.rejected, (state, action) => {
        state.isLoadingOrders = false
        state.isError = true
        state.message = action.payload
        toast.error("Order Update Error")
      })

      .addCase(deleteOrder.pending, (state) => {
        state.isLoadingOrders = true
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.isLoadingOrders = false
        state.isSuccess = true
        toast.success("Order Deleted Successfully")
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.isLoadingOrders = false
        state.isError = true
        state.message = action.payload
        toast.error("Order Delete Error")
      })

    
    },
  })

export const { reset } = order_slice.actions
export default order_slice.reducer
