import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveCreationData, loadCreationData } from '../../../../features/company_creation/company_creation_slice';
import { motion } from 'framer-motion';
import RotatingTitle from './RotatingTitle'; // Import the rotating title component
import CompanyThreeColumnPreview from './Company/Company';
import BranchThreeColumnPreview from './Branch/Branch';

const FBCreationPage = () => {
  const dispatch = useDispatch();
  const creationData = useSelector((state) => state.company_creation.creationData);
  const sectionsRef = useRef([]);

  useEffect(() => {
    dispatch(loadCreationData());
  }, [dispatch]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.transform = 'scale(1)';
          entry.target.style.opacity = '1';
        } else {
          entry.target.style.transform = 'scale(0.9)';
          entry.target.style.opacity = '0.8';
        }
      });
    }, {
      threshold: 0.5,
    });

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  const handleSave = () => {
    dispatch(saveCreationData(creationData));
    // Button feedback effect
  };

  return (
    <div className="h-screen w-screen flex flex-col items-center bg-gradient-to-r from-blue-200 to-cyan-200">
      <RotatingTitle /> {/* Insert the rotating title component */}
      <div className="h-full w-full overflow-y-scroll snap-y snap-mandatory pb-10 ">
        {/* First Child Div */}
        <div
  ref={(el) => (sectionsRef.current[0] = el)}
  className="first-child-div h-[95%] w-full max-w-[90%] mx-auto bg-gradient-to-r from-teal-200 to-teal-500 pl-2 pr-2 pb-3 snap-center flex flex-col justify-between rounded-lg border-2 border-white transition-transform duration-150"
  style={{ transform: 'scale(1)', opacity: '1' }}
>
  {/* Title */}
  <div className="h-[5%] flex items-center justify-center">
    <h5 className="text-center text-lg text-white">Profile Page Preview</h5>
  </div>

  {/* Three Column Preview */}
  <div className="h-[90%] my-2 overflow-y-hidden">
    <CompanyThreeColumnPreview />
  </div>

  {/* Save Button */}
  <div className="h-[5%] flex items-center justify-center mt-2">
    <motion.button
      className="px-4 py-2 bg-teal-700 text-white rounded"
      onClick={handleSave}
      whileTap={{ scale: 0.9 }}
    >
      Save
    </motion.button>
  </div>
</div>


        {/* Second Child Div */}
      <div
  ref={(el) => (sectionsRef.current[0] = el)}
  className="second-child-div h-[95%]  w-full max-w-[90%] mx-auto bg-gradient-to-b from-violet-400 via-indigo-500 to-blue-500 pl-2 pr-2 pb-3 snap-center flex flex-col justify-between rounded-lg border-2 border-white transition-transform duration-150"
  style={{ transform: 'scale(1)', opacity: '1' }}
>
  {/* Title */}
  <div className="h-[5%] flex items-center justify-center">
    <h5 className="text-center text-lg text-white">Profile Page Preview</h5>
  </div>

  {/* Three Column Preview */}
  <div className="h-[90%] my-2 overflow-y-hidden">
    <BranchThreeColumnPreview />
  </div>
          <div className="flex justify-center mt-4">
            <motion.button
              className="ml-auto mr-4 px-4 py-2 bg-indigo-700 text-white rounded"
              onClick={handleSave}
              whileTap={{ scale: 0.9 }}
            >
              Save
            </motion.button>
          </div>
        </div>

        {/* Third Child Div */}
        <div
          ref={(el) => (sectionsRef.current[2] = el)}
          className="third-child-div h-[95%]  w-full max-w-[90%] mx-auto bg-gradient-to-r from-blue-300 to-cyan-300 pl-2 pr-2 pb-3 snap-center flex flex-col justify-between rounded-lg border-2 border-white transition-transform duration-150"
          style={{ transform: 'scale(0.9)', opacity: '0.8' }}
        >
          <h2 className="text-center text-2xl text-white">Add Items</h2>
          <div className="flex justify-center mt-4">
            <motion.button
              className="ml-auto mr-4 px-4 py-2 bg-cyan-700 text-white rounded"
              onClick={handleSave}
              whileTap={{ scale: 0.9 }}
            >
              Save
            </motion.button>
          </div>
        </div>

        {/* Fourth Child Div */}
        <div
          ref={(el) => (sectionsRef.current[3] = el)}
          className="fourth-child-div h-[95%]  w-full max-w-[90%] mx-auto bg-gradient-to-r from-amber-500 to-pink-500 pl-2 pr-2 pb-3 snap-center flex flex-col justify-between rounded-lg border-2 border-white transition-transform duration-150"
          style={{ transform: 'scale(0.9)', opacity: '0.8' }}
        >
          <h2 className="text-center text-2xl text-white">Complete the Creation</h2>
          <div className="flex justify-center mt-4">
            <motion.button
              className="ml-auto mr-4 px-4 py-2 bg-pink-700 text-white rounded"
              onClick={handleSave}
              whileTap={{ scale: 0.9 }}
            >
              Save
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FBCreationPage;
