import axios from 'axios';

// const API_URL = '/api/craveStore/';
const appStatus = "production"; // Or "development", set this variable as needed

const API_URL = appStatus === "production" 
  ? "https://crave-back-aa2b2df96a15.herokuapp.com/api/craveStore/" 
  : "http://localhost:5000/api/craveStore/";
// Fetch Store Items
const getItems = async () => {
  const response = await axios.get(API_URL + 'items');
  return response.data;
};

// Purchase Item
const purchaseItem = async (item, amount) => {
  const response = await axios.post(API_URL + 'purchase', { item, amount });
  return response.data;
};

const carve_store_service = {
  getItems,
  purchaseItem,
};

export default carve_store_service;
