const dummyCompany ={
    name: "BrewSpot Coffee",
    public_name: "BrewSpot Coffee",
    company_type: "Coffee Shop",
    admin_limit: 5,
    current_users_active: 1,
    country: "USA",
    city: "Seattle",
    email: "contact@brewspotcoffee.com",
    password: "default_password",
    keywords_list: ["Specialty Coffee", "Breakfast", "Desserts"],
      top_five_items: [
        {
          "name": "Espresso",
          "img_url": "https://images.pexels.com/photos/302901/pexels-photo-302901.jpeg"
        },
        {
          "name": "Latte",
          "img_url": "https://images.pexels.com/photos/597933/pexels-photo-597933.jpeg"
        },
        {
          "name": "Cappuccino",
          "img_url": "https://images.pexels.com/photos/3402423/pexels-photo-3402423.jpeg"
        },
        {
          "name": "Cold Brew",
          "img_url": "https://images.pexels.com/photos/3184183/pexels-photo-3184183.jpeg"
        },
        {
          "name": "Americano",
          "img_url": "https://images.pexels.com/photos/302899/pexels-photo-302899.jpeg"
        }
      ],
        loading_info: {
      enter_home_Loading: "Default",
      enter_menu_loading: "Default",
      enter_challenge_loading: "Default"
    },
    allowed_info: {
      paid_for_cart: "No"
    },
    rules: {
      return_policy: "Return within 30 days",
      exchange_policy: "Exchange within 15 days"
    },
    reached_out_info: {
      registered_by: "Within Reach",
      registered_at: "2024-11-19T16:00:00Z"
    },
    menu_info: {
      season_style: "Seasonal",
      main_categs_list: [
        { _id: "1", name: "Coffee" },
        { _id: "2", name: "Refreshers" },
        { _id: "3", name: "Food" },
        { _id: "4", name: "Desserts" },
        { _id: "5", name: "Merch & Others" }
      ]
    },
    timings: {
      Monday: "8am - 10pm",
      Tuesday: "8am - 10pm",
      Wednesday: "8am - 10pm",
      Thursday: "8am - 10pm",
      Friday: "8am - 10pm",
      Saturday: "8am - 10pm",
      Sunday: "8am - 10pm",
    },
    open_status: "Open",
    amenities: {
      wifi: "Yes",
      indoor_outdoor: "Indoor",
      Washroom: "Yes",
      Pets: "No",
      dine_in: "Dine-in & Takeout"
    },
     rating : {
      general: "Great Experience!",
      total_votes: 120, // Total number of overall ratings or reviews
      food_quality: {
        description: "Outstanding taste and presentation",
        total_thumbs: 150, // Total thumbs in this category
        up_thumbs: 130, // Positive thumbs
        down_thumbs: 20 // Negative thumbs
      },
      service_quality: {
        description: "Friendly and professional staff",
        total_thumbs: 140, // Total thumbs in this category
        up_thumbs: 120, // Positive thumbs
        down_thumbs: 20 // Negative thumbs
      },
      value_for_money: {
        description: "Good portions for the price",
        total_thumbs: 130, // Total thumbs in this category
        up_thumbs: 100, // Positive thumbs
        down_thumbs: 30 // Negative thumbs
      }
    },
    footer_copyright: "© 2024 BrewSpot Coffee",
    profile_pic_url: "https://example.com/images/brewspot-logo.png"
  }
  export default dummyCompany