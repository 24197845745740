import axios from 'axios';
// const API_URL = '/api/powerUps/';
const appStatus = "production"; // Or "development", set this variable as needed

const API_URL = appStatus === "production" 
  ? "https://crave-back-aa2b2df96a15.herokuapp.com/api/powerUps/" 
  : "http://localhost:5000/api/powerUps/";
// Register PowerUp
const registerPowerUp = async (powerUpData) => {
  try {
    const response = await axios.post(API_URL + 'create', powerUpData);
    return response.data;
  } catch (error) {
    console.error('Error registering powerup:', error);
    throw error.response?.data?.message || 'An error occurred while registering the powerup';
  }
};

// Get PowerUp by ID
const getPowerUp = async (id) => {
  try {
    const response = await axios.get(`${API_URL}getOne/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching powerup:', error);
    throw error.response?.data?.message || 'An error occurred while fetching the powerup';
  }
};

// Get PowerUp Group by Sub-Category
const getPowerUpGroup = async (sub_category) => {
  try {
    const response = await axios.get(`${API_URL}group`, { params: { sub_category } });
    return response.data;
  } catch (error) {
    console.error('Error fetching powerup group:', error);
    throw error.response?.data?.message || 'An error occurred while fetching the powerup group';
  }
};

// Get All PowerUps
const getAllPowerUps = async () => {
  try {
    const response = await axios.get(API_URL + 'getAll');
    return response.data;
  } catch (error) {
    console.error('Error fetching all powerups:', error);
    throw error.response?.data?.message || 'An error occurred while fetching all powerups';
  }
};

// Update PowerUp by ID
const updatePowerUp = async (id, powerUpData) => {
  try {
    const response = await axios.put(`${API_URL}update/${id}`, powerUpData);
    return response.data;
  } catch (error) {
    console.error('Error updating powerup:', error);
    throw error.response?.data?.message || 'An error occurred while updating the powerup';
  }
};

// Update PowerUp Group by Sub-Category
const updatePowerUpGroup = async (sub_category, updates) => {
  try {
    const response = await axios.put(`${API_URL}updateGroup`, { sub_category, updates });
    return response.data;
  } catch (error) {
    console.error('Error updating powerup group:', error);
    throw error.response?.data?.message || 'An error occurred while updating the powerup group';
  }
};

// Delete PowerUp by ID
const deletePowerUp = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting powerup:', error);
    throw error.response?.data?.message || 'An error occurred while deleting the powerup';
  }
};

// Delete PowerUp Group by Sub-Category
const deletePowerUpGroup = async (sub_category) => {
  try {
    const response = await axios.delete(`${API_URL}group`, { params: { sub_category } });
    return response.data;
  } catch (error) {
    console.error('Error deleting powerup group:', error);
    throw error.response?.data?.message || 'An error occurred while deleting the powerup group';
  }
};

export const givePowerUpToUser = (data) => async (dispatch) => {
  try {
    const { userId, powerUpId, main_category, powerup_name } = data;
    const response = await axios.put(`/api/powerUps/give/${userId}/${powerUpId}`, null, {
      params: {
        main_category,
        powerup_name
      }
    });

    dispatch({
      type: 'GIVE_POWERUP_SUCCESS',
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: 'GIVE_POWERUP_FAIL',
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};


// Fetch Sub-Categories
const getSubCategories = async () => {
  try {
    const response = await axios.get('/api/powerUps/subCategories');
    return response.data;
  } catch (error) {
    console.error('Error fetching sub-categories:', error);
    throw error.response?.data?.message || 'An error occurred while fetching sub-categories';
  }
};

// Fetch PowerUp Group by Sub-Category and Name
const getPowerUpGroupBySubCategoryAndName = async (subCategory, searchTerm) => {
  try {
    const response = await axios.get('/api/powerUps/groupBySubCategoryAndName', {
      params: { sub_category: subCategory, name: searchTerm }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching powerup group:', error);
    throw error.response?.data?.message || 'An error occurred while fetching the powerup group';
  }
};



const powerUpService = {
  registerPowerUp,
  getPowerUp,
  getPowerUpGroup,
  getAllPowerUps,
  updatePowerUp,
  updatePowerUpGroup,
  deletePowerUp,
  deletePowerUpGroup,
  givePowerUpToUser,
  getSubCategories,
  getPowerUpGroupBySubCategoryAndName,};

export default powerUpService;

