import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import user_service from './user_service';
import toast from 'react-hot-toast';
import { persistor } from '../../app/store';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
  users: [],
  currentUser: user ? user : null,
  selectedUser: null,
  totalPages: 0,
  newlyRegistered: {},
  updatedUser: {},
  oneUserCalled: {},
  ourCompany: {},
  may_not_have_account: false,
  has_account_but_logged_out: false,
  isError: false,
  isSuccess: false,
  isRegisterSuccess: false,
  isLoading: false,
  isRehydrating: false,
  message: '',
};

// Register User
export const registerUser = createAsyncThunk(
  'users_auth/registerUser',
  async (user, thunkAPI) => {
    try {
      const response = await user_service.registerUser(user);
      await persistor.flush(); // Trigger rehydration
      return response;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Login User
export const loginUser = createAsyncThunk(
  'users_auth/loginUser',
  async (user, thunkAPI) => {
    try {
      const response = await user_service.loginUser(user);
      await persistor.flush(); // Trigger rehydration
      return response;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Logout User
export const logoutUser = createAsyncThunk('users_auth/logoutUser', async () => {
  await user_service.logoutUser();
});

// Share Bucks with a Friend
export const shareBucks = createAsyncThunk(
  'users/shareBucks',
  async ({ friend, amount }, thunkAPI) => {
    try {
      const response = await user_service.shareBucks(friend, amount);
      await persistor.flush(); // Trigger rehydration
      return response;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Deduct Bucks Thunk Action
export const deductBucks = createAsyncThunk(
  'users/deductBucks',
  async ({ userId, deductionAmount }, thunkAPI) => {
    try {
      const response = await user_service.deductBucks(userId, deductionAmount);
      await persistor.flush(); // Trigger rehydration
      return response;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Fetch Users
export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async ({ pageNumber, searchTerm }, thunkAPI) => {
    try {
      const response = await user_service.searchAndFetchUsers(pageNumber, searchTerm);
      await persistor.flush(); // Trigger rehydration
      return response;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Set Selected User
export const setSelectedUser = createAsyncThunk(
  'users/setSelectedUser',
  async (user, thunkAPI) => {
    await persistor.flush(); // Trigger rehydration
    return user;
  }
);

// Get All Users
export const getUsers = createAsyncThunk(
  'users_auth/getAllUsers',
  async (user_info, thunkAPI) => {
    try {
      console.log("Getting Users");
      const response = await user_service.getUsers(user_info);
      await persistor.flush(); // Trigger rehydration
      return response;
    } catch (error) {
      console.log("Can't Get Users");
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get One User
export const getOneUser = createAsyncThunk(
  'users_auth/getOneUsers',
  async (user_info, thunkAPI) => {
    try {
      const response = await user_service.getOneUser(user_info);
      await persistor.flush(); // Trigger rehydration
      return response;
    } catch (error) {
      console.log("Can't Get Users");
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Me
export const getMe = createAsyncThunk(
  'users_auth/getMe',
  async (user_info, thunkAPI) => {
    try {
      const response = await user_service.getMe(user_info);
      await persistor.flush(); // Trigger rehydration
      return response;
    } catch (error) {
      console.log("Can't Get Users");
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update User
export const updateUserInfo = createAsyncThunk(
  'users_auth/updateUserInfo',
  async (user_info, thunkAPI) => {
    try {
      const response = await user_service.updateUserInfo(user_info);
      await persistor.flush(); // Trigger rehydration
      return response;
    } catch (error) {
      console.log("Can't Update Users");
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete User
export const deleteUser = createAsyncThunk(
  'users_auth/deleteUser',
  async (user_info, thunkAPI) => {
    try {
      const response = await user_service.deleteUser(user_info);
      await persistor.flush(); // Trigger rehydration
      return response;
    } catch (error) {
      console.log("Can't Delete User");
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const user_slice = createSlice({
  name: 'users_auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isRegisterSuccess = false;
      state.isError = false;
      state.message = '';
      state.isRehydrating = false;
    },
    clearAllSmallStates(state) { 
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
        state.isRehydrating = false;
        toast.loading('Registering...');
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isRegisterSuccess = true;
        state.newlyRegistered = action.payload;
        state.currentUser = action.payload;
        state.isRehydrating = true; // Set rehydrating to true
        toast.dismiss();
        toast.success('You are Registered and Logged In');
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.newlyRegistered = null;
        state.currentUser = null; // Reset currentUser on error
        state.isRehydrating = false; // Set rehydrating to false
        toast.dismiss();
        toast.error('User Registration Error');
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.isRehydrating = false;
        toast.loading('Logging in...');
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.currentUser = action.payload;
        state.isRehydrating = true; // Set rehydrating to true
        toast.dismiss();
        toast.success('You are Logged In');
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.currentUser = null; // Reset currentUser on error
        state.isRehydrating = false; // Set rehydrating to false
        toast.dismiss();
        toast.error('User Login Error');
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.currentUser = null;
        state.newlyRegistered = null;
        state.isRegisterSuccess = false;
        state.isRehydrating = false; // No rehydration needed for logout
        toast.success('Logged Out Successfully');
      })
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users = action.payload.users;
        state.totalPages = action.payload.pages;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error('Error fetching users');
      })
      .addCase(setSelectedUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
      })
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.fullUserList = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOneUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.oneUserCalled = action.payload;
        state.isRehydrating = true; // Set rehydrating to true
        toast.success("User Info Ready");
      })
      .addCase(getOneUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isRehydrating = false; // Set rehydrating to false
        toast.error("Getting User Info Error");
      })
      .addCase(getMe.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const comp = action.payload;
        state.currentUser = action.payload;
        state.userAuthed = "Yes";
        state.isRehydrating = true; // Set rehydrating to true
        const user_loc = {_id: comp._id, name: comp.name, user_type: comp.user_type};
        localStorage.setItem('loc_user', JSON.stringify(user_loc));
        toast.success("User Info Ready");
      })
      .addCase(getMe.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isRehydrating = false; // Set rehydrating to false
        toast.error("Getting User Info Error");
      })
      .addCase(updateUserInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updatedUser = action.payload;
        const newFullList = state.fullUserList.map(comp => comp._id === action.payload._id ? action.payload : comp);
        state.fullUserList = newFullList;
        state.isRehydrating = true; // Set rehydrating to true
        toast.success("User Updated Successfully");
      })
      .addCase(updateUserInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isRehydrating = false; // Set rehydrating to false
        toast.error("User Update Error");
      })
      .addCase(shareBucks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(shareBucks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isRehydrating = true; // Set rehydrating to true
        toast.success('Bucks shared successfully!');
      })
      .addCase(shareBucks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isRehydrating = false; // Set rehydrating to false
        toast.error(action.payload);
      })
      .addCase(deductBucks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deductBucks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.currentUser = {
          ...state.currentUser,
          my_wallet: {
            ...state.currentUser.my_wallet,
            current_amount: state.currentUser.my_wallet.current_amount - action.payload.deductionAmount,
            total_spent: {
              ...state.currentUser.my_wallet.total_spent,
              ever: state.currentUser.my_wallet.total_spent.ever + action.payload.deductionAmount,
              this_year: state.currentUser.my_wallet.total_spent.this_year + action.payload.deductionAmount,
              this_month: state.currentUser.my_wallet.total_spent.this_month + action.payload.deductionAmount,
              this_week: state.currentUser.my_wallet.total_spent.this_week + action.payload.deductionAmount,
            },
          },
        };
        state.isRehydrating = true; // Set rehydrating to true
        toast.success('Bucks deducted successfully!');
      })
      .addCase(deductBucks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isRehydrating = false; // Set rehydrating to false
        toast.error('Error deducting bucks');
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isRehydrating = true; // Set rehydrating to true
        toast.success("User Deleted Successfully");
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isRehydrating = false; // Set rehydrating to false
        toast.error("User Delete Error");
      })
    },
  })

export const { reset, clearAllSmallStates } = user_slice.actions
export default user_slice.reducer
