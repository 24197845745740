import axios from 'axios'

const API_URL = 'http://localhost:5000/api/comments/'

// Register comment
const registerComment = async (commentData) => {
  const response = await axios.post(API_URL + 'register',commentData)
  if (response.data) {
    console.log(response.data)
    // localStorage.setItem('commentRegistered', JSON.stringify(response.data))
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
}
// Get All comments
const getComments  = async (addNumber) => {
  console.log(addNumber)
  // const response = await axios.get(API_URL + 'get' , addNumber)
  const response = await axios({
    method: 'get',
    url: API_URL+'get',
    params: {addNumber: addNumber},
  })
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Get One comment
const getOneComment  = async (comment_id) => {
  const response = await axios.get(API_URL + 'getOne' , comment_id)
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Update One comment
const updateCommentInfo  = async (compData) => {
  // const response = await axios.put(API_URL + 'update' , compData)
  const response = await axios({
    method: 'put',
    url: API_URL+'update',
    params: compData,
  })
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Delete One comment
const deleteComment  = async (comment_id) => {
  const response = await axios.delete(API_URL + 'delete' , comment_id)
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data  
} 
// //Enter comment Profile
// const commentLogin = async (commentData) => {
//   const response = await axios.post(API_URL + 'entercomment', commentData)
//   console.log((commentData)+ 'sent from service')
//   console.log((response.data)+ 'sent from service')

//   let comp = JSON.parse(localStorage.getItem('CompInProfile')) 
//   if (comp === null) {
//     if (response.data) {
//       localStorage.setItem('CompInProfile', JSON.stringify(response.data))
//     }
//   }
//   return response.data
// }

const commentAuthService = {
  registerComment,  
  getComments,  
  getOneComment,  
  updateCommentInfo,  
  deleteComment, 
}
export default commentAuthService
