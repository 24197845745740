import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import powerUpPieceService from './powerUpPiece_Service';
const appVersion = '1.0.0' // Your current app version

const initialState = {
  powerUpPieces: [],
  powerUpPiece: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
  appVersion, // Add app version to state
};

// Async Thunks
export const registerPowerUpPiece = createAsyncThunk('powerUpPieces/register', async (powerUpPieceData, thunkAPI) => {
  try {
    return await powerUpPieceService.registerPowerUpPiece(powerUpPieceData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getPowerUpPiece = createAsyncThunk('powerUpPieces/getOne', async (id, thunkAPI) => {
  try {
    return await powerUpPieceService.getPowerUpPiece(id);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAllPowerUpPieces = createAsyncThunk('powerUpPieces/getAll', async (_, thunkAPI) => {
  try {
    return await powerUpPieceService.getAllPowerUpPieces();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updatePowerUpPiece = createAsyncThunk('powerUpPieces/update', async (data, thunkAPI) => {
  const { id, powerUpPieceData } = data;
  try {
    return await powerUpPieceService.updatePowerUpPiece(id, powerUpPieceData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getPowerUpPieceGroup = createAsyncThunk('powerUpPieces/group', async (original_powerup_id, thunkAPI) => {
  try {
    return await powerUpPieceService.getPowerUpPieceGroup(original_powerup_id);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deletePowerUpPiece = createAsyncThunk('powerUpPieces/delete', async (id, thunkAPI) => {
  try {
    return await powerUpPieceService.deletePowerUpPiece(id);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Slice
const powerUpPieceSlice = createSlice({
  name: 'powerUpPieces',
  initialState,
  reducers: {
    reset: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerPowerUpPiece.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerPowerUpPiece.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.powerUpPieces.push(action.payload);
      })
      .addCase(registerPowerUpPiece.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPowerUpPiece.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPowerUpPiece.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.powerUpPiece = action.payload;
      })
      .addCase(getPowerUpPiece.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllPowerUpPieces.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPowerUpPieces.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.powerUpPieces = action.payload;
      })
      .addCase(getAllPowerUpPieces.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updatePowerUpPiece.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePowerUpPiece.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.powerUpPieces.findIndex((piece) => piece._id === action.payload._id);
        if (index !== -1) {
          state.powerUpPieces[index] = action.payload;
        }
      })
      .addCase(updatePowerUpPiece.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPowerUpPieceGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPowerUpPieceGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.powerUpPieces = action.payload;
      })
      .addCase(getPowerUpPieceGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deletePowerUpPiece.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePowerUpPiece.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.powerUpPieces = state.powerUpPieces.filter((piece) => piece._id !== action.payload.id);
      })
      .addCase(deletePowerUpPiece.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = powerUpPieceSlice.actions;
export default powerUpPieceSlice.reducer;
