import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './app/store.js';
import SimpleSpinner from './Pages/Components/Loading/Spinners/SimpleSpinner.jsx';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

// Make sure to persist the state
persistor.persist();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<SimpleSpinner />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
