import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import company_service from './company_service';
import toast from 'react-hot-toast';

// Get company from localStorage
const company = JSON.parse(localStorage.getItem('company'));

const initialState = {
  newlyRegistered:{},
  currentCompany:null,
  companyLoaded:"",
  updatedCompany:{},
  fullCompanyList:[],
  fetchedCompanyList:[],
  ourCompany:{},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Register Company
export const registerCompany = createAsyncThunk(
  'company_auth/registerCompany',
  async (company, thunkAPI) => {
    try {
      return await company_service.registerCompany(company)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get All Companies
export const getCompanies = createAsyncThunk(
  'company_auth/getAllCompanies',
  async (company_info, thunkAPI) => {
    try {
      return await company_service.getCompanies(company_info)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get One Company
export const getOneCompany = createAsyncThunk(
  'company_auth/getOneCompany',
  async (company_info, thunkAPI) => {
    try {
      return await company_service.getOneCompany(company_info)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Enter Company
export const enterCompany = createAsyncThunk(
  'company_auth/enterCompany',
  async (company_info, thunkAPI) => {
    try {
      return await company_service.enterCompany(company_info)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Update Company
export const updateCompanyInfo = createAsyncThunk(
  'company_auth/updateCompanyInfo',
  async (company_info, thunkAPI) => {
    try {
      return await company_service.updateCompanyInfo(company_info)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Update Company Reach
export const updateCompanyReach = createAsyncThunk(
  'company_auth/updateCompanyReach',
  async (company_info, thunkAPI) => {
    try {
      return await company_service.updateCompanyReach(company_info)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete Company
export const deleteCompany = createAsyncThunk(
  'company_auth/deleteCompany',
  async (company_info, thunkAPI) => {
    try {
      return await company_service.deleteCompany(company_info)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Enter Company Profile 
export const companyLogin = createAsyncThunk('company_auth/companyLogin', async (company, thunkAPI) => {
  try {
    // console.log(company._id)
    // console.log(company.companyName)
    console.log(`${company} sent from slice`)

    return await company_service.companyLogin(company)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})


// Fetch Companies
export const fetchCompanies = createAsyncThunk(
  'company/fetchCompanies',
  async (skip, thunkAPI) => {
    try {
      return await company_service.fetchCompanies(skip);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);





export const company_slice = createSlice({
  name: 'company_auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
    setCurrentCompany: (state, action) => { state.currentCompany = action.payload; },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerCompany.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerCompany.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.newlyRegistered = action.payload
        state.currentCompany = action.payload
        state.companyLoaded = "Loaded"
        const comp = action.payload
        localStorage.setItem('loc_comp_name', JSON.stringify(comp.name))
        toast.success("Registered Company Successfully")
      })
      .addCase(registerCompany.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.newlyRegistered = null
        toast.error("Company Registration Error")
      })
      .addCase(getCompanies.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.fullCompanyList = action.payload
      })
      .addCase(getCompanies.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
    
      .addCase(fetchCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        
        // Check for existing companies before adding
        const newCompanies = action.payload.filter(newCompany => 
          !state.fetchedCompanyList.some(existingCompany => existingCompany._id === newCompany._id)
        );
      
        state.fetchedCompanyList = [...state.fetchedCompanyList, ...newCompanies];
      })
      
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOneCompany.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOneCompany.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.currentCompany = action.payload
        state.companyLoaded = "Loaded"
        const comp = action.payload
        localStorage.setItem('loc_comp_name', JSON.stringify(comp.name))
        toast.success("Company Info Ready")
      })
      .addCase(getOneCompany.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Getting Company Info Error")
      })
      .addCase(enterCompany.pending, (state) => {
        state.isLoading = true
      })
      .addCase(enterCompany.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.currentCompany = action.payload
        state.companyLoaded = "Loaded"
        const comp = action.payload
        localStorage.setItem('loc_comp_name', JSON.stringify(comp.name))
        toast.success("Company Info Ready")
      })
      .addCase(enterCompany.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Getting Company Info Error")
      })
      .addCase(updateCompanyInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCompanyInfo.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.updatedCompany = action.payload
        const newFullList = state.fullCompanyList.map(comp => comp._id === state.updatedCompany._id ? state.updatedCompany : comp);
        state.fullCompanyList = newFullList;
        const comp = action.payload
        localStorage.setItem('up_loc_comp_name', JSON.stringify(comp.name))
        toast.success("Company Updated Successfully")
      })
      .addCase(updateCompanyInfo.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Company Update Error")
      })
      // ...............................................
      .addCase(updateCompanyReach.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCompanyReach.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.updatedCompany = action.payload
        const newFullList = fullCompanyList;
        newFullList.map(comp => comp._id === updatedCompany._id ? updatedCompany : comp);
        state.fullCompanyList = newFullList;
        toast.success("Company Updated Successfully")
      })
      .addCase(updateCompanyReach.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Company Update Error")
      })
      // ...............................................
      // ...............................................

      .addCase(deleteCompany.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        toast.success("Company Deleted Successfully")
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Company Delete Error")
      })

      
      .addCase(companyLogin.pending, (state) => {
        state.isLoading = true
      })
      .addCase(companyLogin.fulfilled, (state, action) => {
        state.isLoading = false   
        state.isSuccess = true
        state.currentCompany = action.payload
        state.companyLoaded = "Loaded"
      })
      .addCase(companyLogin.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
    },
  })

export const { reset, setCurrentCompany } = company_slice.actions
export default company_slice.reducer
