import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import { UserNavs } from './Pages/Screens/UserNavs';
import SimpleSpinner from './Pages/Components/Loading/Spinners/SimpleSpinner';
import FBCreationPage from './Pages/Screens/EntityCreation/F&B/FBCreationPage';

// Importing the new components


const HomeScreen = lazy(() => import('./Pages/Screens/HomeScreen'));
const Me = lazy(() => import('./Pages/Screens/Me/Me'));
const Places = lazy(() => import('./Pages/Screens/Places/Places'));
const Store = lazy(() => import('./Pages/Screens/Store/Store'));
const Community = lazy(() => import('./Pages/Screens/Community/Community/Community'));
const HomeLoadingPage = lazy(() => import('./Pages/Screens/HomeLoadingPage'));
const FindCompany = lazy(() => import('./Pages/Screens/CompanyScreens/FindCompany'));
const CrudTemplateItems = lazy(() => import('./Pages/WithinReachPages/CrudTemplateItems'));
const CrudItems = lazy(() => import('./Pages/Screens/CompanyAdmin/Items/Create'));
const CrudUsers = lazy(() => import('./Pages/WithinReachPages/CrudUsers'));
const CrudCompanies = lazy(() => import('./Pages/WithinReachPages/CrudCompanies'));
const CrudBranches = lazy(() => import('./Pages/WithinReachPages/CrudBranches'));
const Menu = lazy(() => import('./Pages/Screens/CompanyScreens/Menu/App'));
const CompanyHome = lazy(() => import('./Pages/Screens/CompanyScreens/Home'));
const Sales = lazy(() => import('./Pages/Screens/CompanyAdmin/Sales/Sales'));
const Admin = lazy(() => import('./Rules/AdminHome/App'));
const FromOut = lazy(() => import('./Pages/Screens/FromOut'));
const Payment = lazy(() => import('./Pages/Components/Modals/Placing_Order_Modals/Payment'));
const Success = lazy(() => import('./Pages/Components/Modals/Placing_Order_Modals/Success'));
const PowerUps = lazy(() => import('./Pages/Screens/CompanyAdmin/PowerUps/PowerUps'));
const LoginPage = lazy(() => import('./Pages/Screens/UserAuth/Login')); 
const LogoutPage = lazy(() => import('./Pages/Screens/UserAuth/Logout')); 
const RegisterPage = lazy(() => import('./Pages/Screens/UserAuth/Register')); 
const OtpVerificationPage = lazy(() => import('./Pages/Screens/UserAuth/OtpVerification')); // Add the OTP verification page
const TestCarousel = lazy(() => import('./Pages/Screens/Test Zone/Test')); 
const TestStore1 = lazy(() => import('./Pages/Screens/Store/testStore1')); 

const App = () => {
  return (
    <IconContext.Provider value={{ color: "#ffffff", size: "2rem" }}>
      <Router>
        <div>
          <UserNavs />
          <Suspense fallback={<SimpleSpinner />}>
            <Routes>
              <Route path="/testCarousel" element={<TestCarousel />} />
              <Route path="/" element={<HomeScreen />} />
              <Route path="/me" element={<Me />} />
              <Route path="/places" element={<Places />} />
              <Route path="/store" element={<Store />} />
              <Route path="/community" element={<Community />} />
              <Route path="/enterCrave" element={<FromOut />} />
              <Route path="/crudUsers" element={<CrudUsers />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/success" element={<Success />} />
              <Route path="/crudTemplateItems" element={<CrudTemplateItems />} />
              <Route path="/crudItems" element={<CrudItems />} />
              <Route path="/crudCompanies" element={<CrudCompanies />} />
              <Route path="/crudBranches" element={<CrudBranches />} />
              <Route path="/menu" element={<Menu />} />
              <Route path="/powerUps" element={<PowerUps />} />
              <Route path="/companyHome" element={<CompanyHome />} />
              <Route path="/homeLoadingPage" element={<HomeLoadingPage />} />
              <Route path="/:companyName" element={<FindCompany />} />
              <Route path="/auth/login" element={<LoginPage />} />
              <Route path="/auth/logout" element={<LogoutPage />} />
              <Route path="/auth/register" element={<RegisterPage />} />
              <Route path="/auth/otp" element={<OtpVerificationPage />} /> {/* Add OTP verification route */}
              <Route path="/testStore1" element={<TestStore1 />} /> {/* Add OTP verification route */}
              <Route path="/fbcreation" element={<FBCreationPage />} /> {/* Add OTP verification route */}
            </Routes>
          </Suspense>
        </div>
      </Router>
    </IconContext.Provider>
  );
};

export default App;
