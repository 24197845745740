import axios from 'axios';

// const API_URL = 'http://localhost:5000/api/userBoughtFromUs/';
const appStatus = "production"; // Or "development", set this variable as needed

const API_URL = appStatus === "production" 
  ? "https://crave-back-aa2b2df96a15.herokuapp.com/api/userBoughtFromUs/" 
  : "http://localhost:5000/api/userBoughtFromUs/";
// Create or Update User Bought From Us
const createOrUpdateUserBoughtFromUs = async (data) => {
  try {
    const response = await axios.post(API_URL, data);
    console.log("UserBoughtFromUs data:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating or updating UserBoughtFromUs:", error.response?.data || error.message);
    throw error;
  }
};

// Get User Bought From Us
const getUserBoughtFromUs = async (user_id, company_id) => {
  try {
    const response = await axios.get(`${API_URL}${user_id}/${company_id}`);
    console.log("UserBoughtFromUs data:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error getting UserBoughtFromUs:", error.response?.data || error.message);
    throw error;
  }
};

const userBoughtFromUsService = {
  createOrUpdateUserBoughtFromUs,
  getUserBoughtFromUs,
};

export default userBoughtFromUsService;
