import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import comment_service from './comment_service'
import toast from 'react-hot-toast';

// Get Comment from localStorage
const Comment = JSON.parse(localStorage.getItem('Comment'))

const initialState = {
  newlyRegistered:{},
  updatedComment:{},
  oneCommentCalled:{},
  fullCommentList:[],
  ourCompany:{},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Register Comment
export const registerComment = createAsyncThunk(
  'comment_auth/registerComment',
  async (comment, thunkAPI) => {
    try {
      return await comment_service.registerComment(comment)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Get All comments 
export const getComments = createAsyncThunk(
  'comment_auth/getAllComments',
  async (comment_info, thunkAPI) => {
    try {
      console.log("Getting comments")
      return await comment_service.getComments(comment_info)
    } catch (error) {
      console.log("Can't Get comments")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Get One Comment
export const getOneComment = createAsyncThunk(
  'comment_auth/getOneComment',
  async (comment_info, thunkAPI) => {
    try {

      return await comment_service.getOneComment(comment_info)
    } catch (error) {
      console.log("Can't Get comments")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Update Comment 
export const updateCommentInfo = createAsyncThunk(
  'comment_auth/updateCommentInfo',
  async (comment_info, thunkAPI) => {
    try {

      return await comment_service.updateCommentInfo(comment_info)
    } catch (error) {
      console.log("Can't Update comments")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Delete Comment 
export const deleteComment = createAsyncThunk(
  'comment_auth/deleteComment',
  async (comment_info, thunkAPI) => {
    try {

      return await comment_service.deleteComment(comment_info)
    } catch (error) {
      console.log("Can't Delete Comment")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const comments_slice = createSlice({
  name: 'comment_auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerComment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerComment.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.newlyRegistered = action.payload
        toast.success("Registered Comment Successfully")
      })
      .addCase(registerComment.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.newlyRegistered = null
        toast.error("Comment Registration Error")
      })


      .addCase(getComments.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getComments.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.fullCommentList = action.payload
      })
      .addCase(getComments.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })


      .addCase(getOneComment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOneComment.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.oneCommentCalled = action.payload
        toast.success("Comment Info Ready")
      })
      .addCase(getOneComment.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Getting Comment Info Error")
      })


      .addCase(updateCommentInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCommentInfo.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.updatedComment = action.payload
        const newFullList = fullCommentList;
        newFullList.map(comp => comp._id === updatedComment._id ? updatedComment : comp);
        state.fullCommentList = newFullList;
        toast.success("Comment Updated Successfully")
      })
      .addCase(updateCommentInfo.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Comment Update Error")
      })

      .addCase(deleteComment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        toast.success("Comment Deleted Successfully")
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Comment Delete Error")
      })

    
    },
  })

export const { reset } = comments_slice.actions
export default comments_slice.reducer
