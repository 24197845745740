import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import branch_service from './branch_service'
import toast from 'react-hot-toast';

// Get branch from localStorage
const branch_local = JSON.parse(localStorage.getItem('branch_local'))

const appVersion = '1.0.0' // Your current app version

const initialState = {
  newlyRegistered: {},
  currentBranch: branch_local ? branch_local : {},
  updatedBranch: {},
  oneBranchCalled: {},
  fullBranchList: [],
  ourCompany: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  appVersion, // Add app version to state
}

// Register branch
export const registerBranch = createAsyncThunk(
  'branch_auth/registerBranch',
  async (branch, thunkAPI) => {
    try {
      return await branch_service.registerBranch(branch)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Get All branch 
export const getBranches = createAsyncThunk(
  'branch_auth/getAllBranches',
  async (branch_info, thunkAPI) => {
    try {
      console.log("Getting branch")
      return await branch_service.getBranches(branch_info)
    } catch (error) {
      console.log("Can't Get branch")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Get One branch
export const getOneBranch = createAsyncThunk(
  'branch_auth/getOneBranch',
  async (branch_info, thunkAPI) => {
    try {
      return await branch_service.getOneBranch(branch_info)
    } catch (error) {
      console.log("Can't Get branch")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Update Branch Items 
export const updateBranchItems = createAsyncThunk(
  'branch_auth/updateBranchItems',
  async (branch_info, thunkAPI) => {
    try {
      return await branch_service.updateBranchItems(branch_info)
    } catch (error) {
      console.log("Can't Update Branch Items")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Update branch 
export const updateBranchInfo = createAsyncThunk(
  'branch_auth/updateBranchInfo',
  async (branch_info, thunkAPI) => {
    try {
      return await branch_service.updateBranchInfo(branch_info)
    } catch (error) {
      console.log("Can't Update branch")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Delete branch 
export const deleteBranch = createAsyncThunk(
  'branch_auth/deleteBranch',
  async (branch_info, thunkAPI) => {
    try {
      return await branch_service.deleteBranch(branch_info)
    } catch (error) {
      console.log("Can't Delete branch")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Enter branch Profile 
export const branchLogin = createAsyncThunk('branch_auth/branchLogin', async (branch, thunkAPI) => {
  try {
    // console.log(branch._id)
    // console.log(branch.branchName)
    console.log(`${branch} sent from slice`)

    return await branch_service.branchLogin(branch)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})
export const branch_slice = createSlice({
  name: 'branch_auth',
  initialState,
  reducers: {
    setCurrentBranch(state, action) {
      state.currentBranch = action.payload;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerBranch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.newlyRegistered = action.payload;
        toast.success("Registered branch Successfully");
      })
      .addCase(registerBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.newlyRegistered = null;
        toast.error("Branch Registration Error");
      })
      .addCase(getBranches.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBranches.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.fullBranchList = action.payload;
      })
      .addCase(getBranches.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOneBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneBranch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.oneBranchCalled = action.payload;
        toast.success("Branch Info Ready");
      })
      .addCase(getOneBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error("Getting Branch Info Error");
      })
      .addCase(updateBranchItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBranchItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.currentBranch = action.payload;
        toast.success("Branch Items Updated Successfully");
      })
      .addCase(updateBranchItems.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error("Branch Items Update Error");
      })
      .addCase(updateBranchInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBranchInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updatedBranch = action.payload;
        const newFullList = state.fullBranchList.map(comp => 
          comp._id === action.payload._id ? action.payload : comp);
        state.fullBranchList = newFullList;
        toast.success("Branch Updated Successfully");
      })
      .addCase(updateBranchInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error("Branch Update Error");
      })
      .addCase(deleteBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBranch.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success("Branch Deleted Successfully");
      })
      .addCase(deleteBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error("Branch Delete Error");
      })
      .addCase(branchLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(branchLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.ourCompany = action.payload;
      })
      .addCase(branchLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.ourCompany = null;
      });
  },
});

export const { reset, setCurrentBranch } = branch_slice.actions;
export default branch_slice.reducer;
