import { createSlice } from '@reduxjs/toolkit';

// Initial state with focus on single active rewards and power-ups
const appVersion = '1.0.0' // Your current app version


const initialState = {
  activeAutoReward: null, // Active auto reward
  activePowerUp: null, // Active user-initiated power-up that affects the cart
  activeNonCartPowerUp: null, // Active user-initiated power-up that does not affect the cart
  appliedReward: null, // Store the reward that has been applied
  appVersion, // Add app version to state
};

const perkSlice = createSlice({
  name: 'perks',
  initialState,
  reducers: {
    setActiveAutoReward(state, action) {
      // Set the active auto reward
      state.activeAutoReward = action.payload;
      state.activePowerUp = null; // Clear active power-up if any
      state.appliedReward = action.payload; // Apply the reward
    },
    setActivePowerUp(state, action) {
      // Set the active user power-up that affects the cart
      state.activePowerUp = action.payload;
      state.activeAutoReward = null; // Clear active auto reward if any
      state.appliedReward = action.payload; // Apply the reward
    },
    setActiveNonCartPowerUp(state, action) {
      // Set the active user power-up that does not affect the cart
      state.activeNonCartPowerUp = action.payload;
    },
    resetPerks(state) {
      // Reset all perks and power-ups
      state.activeAutoReward = null;
      state.activePowerUp = null;
      state.activeNonCartPowerUp = null;
      state.appliedReward = null;
    },
  },
});

export const {
  setActiveAutoReward,
  setActivePowerUp,
  setActiveNonCartPowerUp,
  resetPerks,
} = perkSlice.actions;

export default perkSlice.reducer;
