import React, { useState } from 'react';
import { AnimatePresence, motion } from "framer-motion";
import { FiAlertCircle, FiX } from "react-icons/fi";
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import dummyCompany from './testCompany';

const DetailsColumn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inputData, setInputData] = useState({
    company_name: "",
    company_type: "",
    country: "",
    city: "",
    main_phone_number: "",
    email: "",
    admin_limit: "",
    enter_home_Loading: "Default",
    enter_menu_loading: "Default",
    enter_challenge_loading: "Default",
    paid_for_cart: "No",
    return_policy: "",
    exchange_policy: "",
    registered_by: "Within Reach",
    profile_pic_url: "",
    monday: "8am - 10pm",
    tuesday: "8am - 10pm",
    wednesday: "8am - 10pm",
    thursday: "8am - 10pm",
    friday: "8am - 10pm",
    saturday: "8am - 10pm",
    sunday: "8am - 10pm"
  });

  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [openStatus, setOpenStatus] = useState("Open");
  const [isKeywordsOpen, setIsKeywordsOpen] = useState(true);
  const [isAmenitiesOpen, setIsAmenitiesOpen] = useState(true);

  const dummyKeywords = ["coffee", "espresso", "latte", "cappuccino", "brew"];
  const dummyAmenities = ["Wifi", "Toilet", "Parking", "Indoor Seating", "Outdoor Seating"];

  const { 
    company_name, company_type, country, city, main_phone_number, email, admin_limit,
    enter_home_Loading, enter_menu_loading, enter_challenge_loading, paid_for_cart,
    return_policy, exchange_policy, registered_by, profile_pic_url,
    monday, tuesday, wednesday, thursday, friday, saturday, sunday
  } = inputData;

  const onChange = (e) => {
    setInputData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const toggleKeyword = (word) => {
    setSelectedKeywords((prev) => 
      prev.includes(word) ? prev.filter((kw) => kw !== word) : [...prev, word]
    );
  };

  const toggleAmenity = (amenity) => {
    setSelectedAmenities((prev) => 
      prev.includes(amenity) ? prev.filter((am) => am !== amenity) : [...prev, amenity]
    );
  };

  const toggleOpenStatus = () => {
    setOpenStatus((prev) => (prev === "Open" ? "Closed" : "Open"));
  };

  const createCompany = () => {
    const firstDate = new Date();

    let companyData = {
      name: company_name || dummyCompany.name,
      public_name: company_name || dummyCompany.public_name,
      company_type: company_type || dummyCompany.company_type,
      admin_limit: parseInt(admin_limit) || dummyCompany.admin_limit,
      current_users_active: dummyCompany.current_users_active,
      country: country || dummyCompany.country,
      city: city || dummyCompany.city,
      email: email || dummyCompany.email,
      password: dummyCompany.password,
      keywords_list: selectedKeywords.length > 0 ? selectedKeywords : dummyCompany.keywords_list,
      top_five_items: dummyCompany.top_five_items,
      loading_info: {
        enter_home_Loading: enter_home_Loading || dummyCompany.loading_info.enter_home_Loading,
        enter_menu_loading: enter_menu_loading || dummyCompany.loading_info.enter_menu_loading,
        enter_challenge_loading: enter_challenge_loading || dummyCompany.loading_info.enter_challenge_loading
      },
      allowed_info: {
        paid_for_cart: paid_for_cart || dummyCompany.allowed_info.paid_for_cart
      },
      rules: {
        return_policy: return_policy || dummyCompany.rules.return_policy,
        exchange_policy: exchange_policy || dummyCompany.rules.exchange_policy
      },
      reached_out_info: {    
        registered_by: registered_by,
        registered_at: firstDate
      },
      menu_info: dummyCompany.menu_info,
      timings: {
        Monday: monday,
        Tuesday: tuesday,
        Wednesday: wednesday,
        Thursday: thursday,
        Friday: friday,
        Saturday: saturday,
        Sunday: sunday,
        General_timings: `Monday - Sunday: ${monday}`
      },
      open_status: openStatus,
      amenities: selectedAmenities.length > 0 ? selectedAmenities : dummyCompany.amenities,
      rating: dummyCompany.rating,
      footer_copyright: dummyCompany.footer_copyright,
      profile_pic_url: profile_pic_url || dummyCompany.profile_pic_url
    };

    console.log(companyData);
    // setTimeout(() => {
    //   navigate('/crudBranches');
    // }, 500);
  };

  return (
    <div className="h-full w-full flex items-center justify-center
                    backdrop-blur-sm bg-white/30 rounded-lg">
      <div className="h-full w-full flex flex-col gap-4 p-4 
                      text-black overflow-y-scroll">
        <div className="flex flex-col">
          <label className="text-sm">Company Name</label>
          <input
            type='text'
            id='company_name'
            name='company_name'
            value={company_name}
            placeholder={dummyCompany.company_name || "Enter company name"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Company Type</label>
          <input
            type='text'
            id='company_type'
            name='company_type'
            value={company_type}
            placeholder={dummyCompany.company_type || "Enter company type"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Country</label>
          <input
            type='text'
            id='country'
            name='country'
            value={country}
            placeholder={dummyCompany.country || "Enter country"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">City</label>
          <input
            type='text'
            id='city'
            name='city'
            value={city}
            placeholder={dummyCompany.city || "Enter city"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Main Phone Number</label>
          <input
            type='text'
            id='main_phone_number'
            name='main_phone_number'
            value={main_phone_number}
            placeholder={dummyCompany.main_phone_number || "Enter phone number"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Email</label>
          <input
            type='email'
            id='email'
            name='email'
            value={email}
            placeholder={dummyCompany.email || "Enter email"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        {/* <div className="flex flex-col">
          <label className="text-sm">Admin Limit</label>
          <input
            type='number'
            id='admin_limit'
            name='admin_limit'
            value={admin_limit}
            placeholder={dummyCompany.admin_limit || "Enter admin limit"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div> */}
        {/* <div className="flex flex-col">
          <label className="text-sm">Enter Home Loading</label>
          <input
            type='text'
            id='enter_home_Loading'
            name='enter_home_Loading'
            value={enter_home_Loading}
            placeholder={dummyCompany.loading_info.enter_home_Loading || "Enter home loading"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Enter Menu Loading</label>
          <input
            type='text'
            id='enter_menu_loading'
            name='enter_menu_loading'
            value={enter_menu_loading}
            placeholder={dummyCompany.loading_info.enter_menu_loading || "Enter menu loading"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Enter Challenge Loading</label>
          <input
            type='text'
            id='enter_challenge_loading'
            name='enter_challenge_loading'
            value={enter_challenge_loading}
            placeholder={dummyCompany.loading_info.enter_challenge_loading || "Enter challenge loading"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div> */}
        {/* <div className="flex flex-col">
          <label className="text-sm">Paid For Cart</label>
          <input
            type='text'
            id='paid_for_cart'
            name='paid_for_cart'
            value={paid_for_cart}
            placeholder={dummyCompany.allowed_info.paid_for_cart || "Paid for cart"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Return Policy</label>
          <input
            type='text'
            id='return_policy'
            name='return_policy'
            value={return_policy}
            placeholder={dummyCompany.rules.return_policy || "Return policy"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Exchange Policy</label>
          <input
            type='text'
            id='exchange_policy'
            name='exchange_policy'
            value={exchange_policy}
            placeholder={dummyCompany.rules.exchange_policy || "Exchange policy"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div> */}
        <div className="flex flex-col">
            <div className='flex justify-between'>
          <label className="text-sm">Keywords List</label>
          {isKeywordsOpen ? (
              <FiX className="text-black cursor-pointer" onClick={() => setIsKeywordsOpen(false)} />
            ):(
            <div className='h-10 w-10 flex justify-center text-white rounded-full border-2  mb-2 cursor-pointer border-white'onClick={() => setIsKeywordsOpen(true)}>...</div>)}
            </div>
          {isKeywordsOpen ? (
            <div>
              {/* <FiX className="text-black cursor-pointer" onClick={() => setIsKeywordsOpen(false)} /> */}
              <div className="flex flex-wrap mt-2">
                {dummyKeywords.map((keyword, index) => (
                  <div
                    key={index}
                    className={`p-2 rounded-full border-2 mr-2 mb-2 cursor-pointer ${selectedKeywords.includes(keyword) ? 'border-green-500' : 'border-white'}`}
                    onClick={() => toggleKeyword(keyword)}
                  >
                    {keyword}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center cursor-pointer" onClick={() => setIsKeywordsOpen(true)}>
            </div>
          )}
        </div>
        {/* <div className="flex flex-col">
          <label className="text-sm">Top Five Items</label>
          <input
            type='text'
            id='top_five_items'
            name='top_five_items'
            value={top_five_items}
            placeholder={dummyCompany.top_five_items.join(', ') || "Enter top five items"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div> */}
        <div className="flex flex-col">
          <label className="text-sm">Profile Picture URL</label>
          <input
            type='text'
            id='profile_pic_url'
            name='profile_pic_url'
            value={profile_pic_url}
            placeholder={dummyCompany.profile_pic_url || "Enter profile picture URL"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Open Status</label>
          <button
            className={`p-2 rounded-md text-black ${openStatus === 'Open' ? 'bg-green-500' : 'bg-red-500'}`}
            onClick={toggleOpenStatus}
          >
            {openStatus}
          </button>
        </div>
        <div className="flex flex-col">
        <div className='flex justify-between'>
          <label className="text-sm">Amenities</label>
          {isAmenitiesOpen ? (
              <FiX className="text-black cursor-pointer" onClick={() => setIsAmenitiesOpen(false)} />
            ):(
            <div className='h-10 w-10 flex justify-center text-white rounded-full border-2  mb-2 cursor-pointer border-white'onClick={() => setIsAmenitiesOpen(true)}>...</div>)}
            </div>
          {isAmenitiesOpen ? (
            <div>
              {/* <FiX className="text-black cursor-pointer" onClick={() => setIsAmenitiesOpen(false)} /> */}
              <div className="flex flex-wrap mt-2">
                {dummyAmenities.map((amenity, index) => (
                  <div
                    key={index}
                    className={`p-2 rounded-full border-2 mr-2 mb-2 cursor-pointer ${selectedAmenities.includes(amenity) ? 'border-green-500' : 'border-white'}`}
                    onClick={() => toggleAmenity(amenity)}
                  >
                    {amenity}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center cursor-pointer" onClick={() => setIsAmenitiesOpen(true)}>
            
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Monday</label>
          <input
            type='text'
            id='monday'
            name='monday'
            value={monday}
            placeholder={dummyCompany.timings.Monday || "Monday timings"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Tuesday</label>
          <input
            type='text'
            id='tuesday'
            name='tuesday'
            value={tuesday}
            placeholder={dummyCompany.timings.Tuesday || "Tuesday timings"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Wednesday</label>
          <input
            type='text'
            id='wednesday'
            name='wednesday'
            value={wednesday}
            placeholder={dummyCompany.timings.Wednesday || "Wednesday timings"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Thursday</label>
          <input
            type='text'
            id='thursday'
            name='thursday'
            value={thursday}
            placeholder={dummyCompany.timings.Thursday || "Thursday timings"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Friday</label>
          <input
            type='text'
            id='friday'
            name='friday'
            value={friday}
            placeholder={dummyCompany.timings.Friday || "Friday timings"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Saturday</label>
          <input
            type='text'
            id='saturday'
            name='saturday'
            value={saturday}
            placeholder={dummyCompany.timings.Saturday || "Saturday timings"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Sunday</label>
          <input
            type='text'
            id='sunday'
            name='sunday'
            value={sunday}
            placeholder={dummyCompany.timings.Sunday || "Sunday timings"}
            onChange={onChange}
            className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
          />
        </div>
        <button className="btn btn-active btn-primary mt-4" onClick={createCompany}>Create</button>
      </div>
    </div>
  );
};

export default DetailsColumn;
