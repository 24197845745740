import axios from 'axios'

//  const API_URL = 'http://localhost:5000/api/company/'
 const appStatus = "production"; // Or "development", set this variable as needed

 const API_URL = appStatus === "production" 
   ? "https://crave-back-aa2b2df96a15.herokuapp.com/api/company/" 
   : "http://localhost:5000/api/company/";// Register company
const registerCompany = async (companyData) => {
  const response = await axios.post(API_URL + 'register',companyData)
  if (response.data) {
    console.log(response.data)
    localStorage.setItem('company_local', JSON.stringify(response.data))
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
}
// Get All Companies
const getCompanies  = async (addNumber) => {
  console.log(addNumber)
  // const response = await axios.get(API_URL + 'get' , addNumber)
  const response = await axios({
    method: 'get',
    url: API_URL+'get',
    params: {addNumber: addNumber},
  })
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 



const fetchCompanies = async (skip = 0) => {
  const response = await axios.get(API_URL + 'getAll', {
    params: { skip }
  });
  return response.data.companies; // Ensure the response returns the companies array directly
};

const company_service = { fetchCompanies /* other service functions... */ };




// Get One Company
const getOneCompany  = async (comp_id) => {
  const response = await axios({
    method: 'get',
    url: API_URL+'getOne',
    params: {comp_id: comp_id},
  })
  // const response = await axios.get(API_URL + 'getOne' , comp_id)
  if (response.data) {
    console.log(response.data)
    // localStorage.setItem('company_called', JSON.stringify(response.data))
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Get One Company
const enterCompany = async (comp_info) => {
  try {
    const response = await axios.get(API_URL + 'enter', {
      params: comp_info,
    });
    if (response.data) {
      console.log(response.data);
      localStorage.setItem('company_local', JSON.stringify(response.data));
    } else {
      console.error('No data returned');
    }
    return response.data;
  } catch (error) {
    console.error('Error in enterCompany:', error);
    throw error;
  }
};

// Update One Company
const updateCompanyInfo  = async (compData) => {
  // const response = await axios.put(API_URL + 'update' , compData)
  const response = await axios({
    method: 'put',
    url: API_URL+'update',
    params: compData,
  })
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
const updateCompanyReach  = async (compData) => {
  // const response = await axios.put(API_URL + 'update' , compData)
  const response = await axios({
    method: 'put',
    url: API_URL+'updateReach',
    params: compData,
  })
  if (response.data) {
    console.log(response.data)
    // localStorage.setItem('company_called', JSON.stringify(response.data))
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Delete One Company
const deleteCompany  = async (comp_id) => {
  const response = await axios.delete(API_URL + 'delete' , comp_id)
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data  
} 

const companyAuthService = {
  registerCompany,  
  getCompanies,  
  fetchCompanies,  
  enterCompany,  
  getOneCompany,  
  updateCompanyReach,  
  updateCompanyInfo,  
  deleteCompany,
}
export default companyAuthService
