import axios from 'axios';

// const API_URL = 'http://localhost:5000/api/userFriends/';
const appStatus = "production"; // Or "development", set this variable as needed

const API_URL = appStatus === "production" 
  ? "https://crave-back-aa2b2df96a15.herokuapp.com/api/userFriends" 
  : "http://localhost:5000/api/userFriends/";


// Search Users
const searchUsers = async (searchTerm) => {
  try {
    const response = await axios.get(`${API_URL}searchUsers`, {
      params: { searchTerm }
    });
    return response.data;
  } catch (error) {
    console.error('Error searching users:', error);
    throw error;
  }
};


// Add Friend
const addFriend = async (userId, friendId) => {
  try {
    const response = await axios.post(`${API_URL}addFriend`, { userId, friendId });
    return response.data;
  } catch (error) {
    console.error('Error adding friend:', error);
    throw error;
  }
};

// Remove Friend
const removeFriend = async (userId, friendId) => {
  try {
    const response = await axios.post(`${API_URL}removeFriend`, { userId, friendId });
    return response.data;
  } catch (error) {
    console.error('Error removing friend:', error);
    throw error;
  }
};

// Share Bucks
const shareBucks = async (userId, friendId, amount) => {
  try {
    const response = await axios.post(`${API_URL}shareBucks`, { userId, friendId, amount });
    return response.data;
  } catch (error) {
    console.error('Error sharing bucks:', error);
    throw error;
  }
};

// Share Power Up
const sharePowerUp = async (userId, friendId, powerUpId) => {
  try {
    const response = await axios.post(`${API_URL}sharePowerUp`, { userId, friendId, powerUpId });
    return response.data;
  } catch (error) {
    console.error('Error sharing power up:', error);
    throw error;
  }
};

// Get Friends List with Pagination
const getFriendsList = async (userId, limit = 10, skip = 0) => {
  try {
    const response = await axios.get(`${API_URL}getFriendsList`, {
      params: { userId, limit, skip }
    });
    if (response.data) {
      console.log(response.data);
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching friends list:', error);
    throw error;
  }
};

const userAuthService = {
  addFriend,
  removeFriend,
  shareBucks,
  sharePowerUp,
  getFriendsList,
  searchUsers
};

export default userAuthService;
