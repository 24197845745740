import React from 'react';
import DetailsColumn from './DetailsColumn';
import SmartphonePreview from './SmartphonePreview';
import LayoutColumn from './LayoutColumn';

const CompanyThreeColumnPreview = () => {
  return (
    <div className="h-full w-full flex">
      {/* Left Column */}
      <DetailsColumn />

      {/* Middle Column with Smartphone Preview */}
      <SmartphonePreview />

      {/* Right Column */}
      <LayoutColumn />
    </div>
  );
};

export default CompanyThreeColumnPreview;
