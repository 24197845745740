const itemsList = [
    {   name:"Espresso",
        aka:"Espresso",
        price:"18",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Espresso_Only",
        _id:'1',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/espresso.jpg?updatedAt=1728502009856",
        temp:"Hot",
        main_categ: "Coffee",
        sub_categ:"Intense",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Macchiato",
        aka:"Macchiato",
        price:"20",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Small_Milk",
        _id:'2',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/macciato.jpg?updatedAt=1728502009712",
        temp:"Hot",
        main_categ: "Coffee",
        sub_categ:"Intense",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Piccolo",
        aka:"Piccolo",
        price:"21",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Small_Milk",
        _id:'2',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/piccolo.jpg?updatedAt=1728502009886",
        temp:"Hot",
        main_categ: "Coffee",
        sub_categ:"Delicate",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Cortado",
        aka:"Cortado",
        price:"23",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Med_Milk",
        _id:'3',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/cortado.jpg?updatedAt=1728502009687",
        temp:"Hot",
        main_categ: "Coffee",
        sub_categ:"Intense",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Americano",
        aka:"Americano",
        price:"20",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Big_Milk",
        _id:'4',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/americano.jpg?updatedAt=1728502009620",
        temp:"Hot Cold",
        main_categ: "Coffee",
        sub_categ:"Intense",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Cappuccino",
        aka:"Cappuccino",
        price:"23",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Big_Milk",
        _id:'4',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/Capp.jpg?updatedAt=1728502009851",
        temp:"Hot",
        main_categ: "Coffee",
        sub_categ:"Savoury",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Flat White",
        aka:"Flat White",
        price:"23",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Big_Milk",
        _id:'4',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/Flat.jpg?updatedAt=1728502009801",
        temp:"Hot",
        main_categ: "Coffee",
        sub_categ:"Savoury",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Latte",
        aka:"Latte",
        price:"23",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Big_Milk",
        _id:'4',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/Latte.jpg?updatedAt=1728502009805",
        temp:"Hot Cold",
        main_categ: "Coffee",
        sub_categ:"Delicate",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Spanish Latte",
        aka:"Spanish Latte",
        price:"26",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Big_Milk",
        _id:'4',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/Spanish.jpg?updatedAt=1728502009808",
        temp:"Hot Cold",
        main_categ: "Coffee",
        sub_categ:"Yummy",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Pistachio Latte",
        aka:"Pistachio Latte",
        price:"26",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Big_Milk",
        _id:'4',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/Pistachio_latte.png?updatedAt=1731292660126",
        temp:"Hot Cold",
        main_categ: "Coffee",
        sub_categ:"Unique",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Caramel Latte",
        aka:"Caramel Latte",
        price:"26",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Big_Milk",
        _id:'4',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/CaramelLatte.png?updatedAt=1731292235668",
        temp:"Hot Cold",
        main_categ: "Coffee",
        sub_categ:"Savoury",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    // {   name:"Saffron Latte",
    //     aka:"Saffron Latte",
    //     price:"20",
    //     dominant_flavours:[{name:""}],
    //     pos_Position:"1",
    //     menu_Position:"1",
    //     order_options_type:"Big_Milk",
    //     _id:'4',
    //     img_url:"xxxxxxxxxxxx",
    //     temp:"Hot Cold",
    //     main_categ: "Coffee",
    //     sub_categ:"Tasty",
    //     sub_categ_priority:"Random",
    //     auto_rewards_info:{
    //         regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
    //         extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
    //         super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
    //     }
    // },
    // {   name:"Red Velvet Latte",
    //     aka:"Red Velvet Latte",
    //     price:"20",
    //     dominant_flavours:[{name:""}],
    //     pos_Position:"1",
    //     menu_Position:"1",
    //     order_options_type:"Big_Milk",
    //     _id:'4',
    //     img_url:"xxxxxxxxxxxx",
    //     temp:"Hot Cold",
    //     main_categ: "Coffee",
    //     sub_categ:"Flavour",
    //     sub_categ_priority:"Random",
    //     auto_rewards_info:{
    //         regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
    //         extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
    //         super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
    //     }
    // },
    // {   name:"Mocha",
    //     aka:"Mocha",
    //     price:"20",
    //     dominant_flavours:[{name:""}],
    //     pos_Position:"1",
    //     menu_Position:"1",
    //     order_options_type:"Big_Milk",
    //     _id:'4',
    //     img_url:"xxxxxxxxxxxx",
    //     temp:"Hot Cold",
    //     main_categ: "Coffee",
    //     sub_categ:"Savoury",
    //     sub_categ_priority:"Random",
    //     auto_rewards_info:{
    //         regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
    //         extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
    //         super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
    //     }
    // },
    // {   name:"English Breakfast",
    //     aka:"English Breakfast",
    //     price:"20",
    //     dominant_flavours:[{name:""}],
    //     pos_Position:"1",
    //     menu_Position:"1",
    //     order_options_type:"Big_Milk",
    //     _id:'4',
    //     img_url:"xxxxxxxxxxxx",
    //     temp:"Hot Cold",
    //     main_categ: "Coffee",
    //     sub_categ:"Hot Tea",
    //     sub_categ_priority:"Random",
    //     auto_rewards_info:{
    //         regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
    //         extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
    //         super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
    //     }
    // },
    {   name:"Matcha",
        aka:"Matcha",
        price:"26",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Big_Milk",
        _id:'4',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/Matcha.jpg?updatedAt=1728502012807",
        temp:"Hot Cold",
        main_categ: "Coffee",
        sub_categ:"Hot Tea",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    // {   name:"Jasmine",
    //     aka:"Jasmine",
    //     price:"20",
    //     dominant_flavours:[{name:""}],
    //     pos_Position:"1",
    //     menu_Position:"1",
    //     order_options_type:"Big_Milk",
    //     _id:'4',
    //     img_url:"xxxxxxxxxxxx",
    //     temp:"Hot Cold",
    //     main_categ: "Coffee",
    //     sub_categ:"Hot Tea",
    //     sub_categ_priority:"Random",
    //     auto_rewards_info:{
    //         regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
    //         extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
    //         super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
    //     }
    // },
    // {   name:"Earl Grey",
    //     aka:"Earl Grey",
    //     price:"20",
    //     dominant_flavours:[{name:""}],
    //     pos_Position:"1",
    //     menu_Position:"1",
    //     order_options_type:"Big_Milk",
    //     _id:'4',
    //     img_url:"xxxxxxxxxxxx",
    //     temp:"Hot Cold",
    //     main_categ: "Coffee",
    //     sub_categ:"Hot Tea",
    //     sub_categ_priority:"Random",
    //     auto_rewards_info:{
    //         regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
    //         extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
    //         super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
    //     }
    // },
    // {   name:"Green Tea",
    //     aka:"Green Tea",
    //     price:"20",
    //     dominant_flavours:[{name:""}],
    //     pos_Position:"1",
    //     menu_Position:"1",
    //     order_options_type:"Big_Milk",
    //     _id:'4',
    //     img_url:"xxxxxxxxxxxx",
    //     temp:"Hot Cold",
    //     main_categ: "Coffee",
    //     sub_categ:"Hot Tea",
    //     sub_categ_priority:"Random",
    //     auto_rewards_info:{
    //         regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
    //         extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
    //         super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
    //     }
    // },
    // {   name:"English Breakfast Tea",
    //     aka:"English Breakfast Tea",
    //     price:"20",
    //     dominant_flavours:[{name:""}],
    //     pos_Position:"1",
    //     menu_Position:"1",
    //     order_options_type:"Big_Milk",
    //     _id:'4',
    //     img_url:"xxxxxxxxxxxx",
    //     temp:"Hot Cold",
    //     main_categ: "Coffee",
    //     sub_categ:"Hot Tea",
    //     sub_categ_priority:"Random",
    //     auto_rewards_info:{
    //         regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
    //         extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
    //         super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
    //     }
    // },
    {   name:"Peach Iced Tea",
        aka:"Peach Iced Tea",
        price:"20",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Big_Milk",
        _id:'4',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/Peach_ice_tea.png?updatedAt=1731292660109",
        temp:"Hot Cold",
        main_categ: "Coffee",
        sub_categ:"Hot Tea",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    // {   name:"Tripple Berries",
    //     aka:"Tripple Berries",
    //     price:"20",
    //     dominant_flavours:[{name:"Tripple Berries"}],
    //     pos_Position:"1",
    //     menu_Position:"1",
    //     order_options_type:"Mojito",
    //     _id:'5',
    //     img_url:"xxxxxxxxxxxx",
    //     temp:"Cold",
    //     main_categ: "Refreshers",
    //     sub_categ:"Smoothies",
    //     sub_categ_priority:"Random",
    //     auto_rewards_info:{
    //         regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
    //         extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
    //         super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
    //     }
    // },
    {   name:"Triple Berries",
        aka:"B2S",
        price:"25",
        dominant_flavours:[{name:"Strawberry"},{name:"Blueberry"},{name:"Blackberry"}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Mojito",
        _id:'6',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/CRCB.png?updatedAt=1731292660113",
        temp:"Cold",
        main_categ: "Refreshers",
        sub_categ:"Smoothies",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Mango x Passion Fruit",
        aka:"Mango x Passion Fruit",
        price:"25",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Mojito",
        _id:'7',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/Mango_passion.png?updatedAt=1731292660218",
        temp:"Cold",
        main_categ: "Refreshers",
        sub_categ:"Mojito",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Acai Bowl & Smoothie",
        aka:"Acai Bowl & Smoothie",
        price:"35",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Smoothie",
        _id:'8',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/Acai.jpg?updatedAt=1732301874361",
        temp:"Cold",
        main_categ: "Refreshers",
        sub_categ:"Smoothies",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Flamingo",
        aka:"Flamingo",
        price:"25",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Smoothie",
        _id:'9',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/Flamingo.png?updatedAt=1731292660253",
        temp:"Cold",
        main_categ: "Refreshers",
        sub_categ:"Smoothies",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Loaded Avocado Toast",
        aka:"L.A. Toast",
        price:"28",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Smoothie",
        _id:'105',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/avocado%20toast.png?updatedAt=1731292659810",
        temp:"Hot Cold",
        main_categ: "Food",
        sub_categ:"Toast",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Chipotle Ranch Chicken Burrito",
        aka:"C.R.C.B",
        price:"28",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Smoothie",
        _id:'105',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/crcb.jpg?updatedAt=1731292660262",
        temp:"Hot Cold",
        main_categ: "Food",
        sub_categ:"Wraps",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Egg Croissant Sandwich",
        aka:"Egg Croissant Sandwich",
        price:"28",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Food",
        _id:'10',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/Egg%20Croissant.jpg?updatedAt=1731373855561",
        temp:"Hot Cold",
        main_categ: "Food",
        sub_categ:"Sandwiches",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Harmony Bowl",
        aka:"Harmony Bowl",
        price:"28",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Food",
        _id:'11',
        img_url:"xxxxxxxxxxxx",
        temp:"Hot Cold",
        main_categ: "Food",
        sub_categ:"Bowls",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Sebastian Cheesecake",
        aka:"Sebastian Cheesecake",
        price:"28",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Food",
        _id:'12',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/sebastian.jpg?updatedAt=1731373969277",
        temp:"Hot Cold",
        main_categ: "Desserts",
        sub_categ:"Bowls",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Dates Cake",
        aka:"Dates Cake",
        price:"25",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Dessert",
        _id:'13',
        img_url:"xxxxxxxxxxxx",
        temp:"Hot Cold",
        main_categ: "Desserts",
        sub_categ:"Toasted Coffee",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    // {   name:"Latte Cake",
    //     aka:"Latte Cake",
    //     price:"20",
    //     dominant_flavours:[{name:""}],
    //     pos_Position:"1",
    //     menu_Position:"1",
    //     order_options_type:"Dessert",
    //     _id:'14',
    //     img_url:"xxxxxxxxxxxx",
    //     temp:"Hot Cold",
    //     main_categ: "Desserts",
    //     sub_categ:"Toasted Coffee",
    //     sub_categ_priority:"Random",
    //     auto_rewards_info:{
    //         regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
    //         extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
    //         super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
    //     }
    // },
    // {   name:"Eclair",
    //     aka:"Eclair",
    //     price:"20",
    //     dominant_flavours:[{name:""}],
    //     pos_Position:"1",
    //     menu_Position:"1",
    //     order_options_type:"Dessert",
    //     _id:'15',
    //     img_url:"xxxxxxxxxxxx",
    //     temp:"Hot Cold",
    //     main_categ: "Desserts",
    //     sub_categ:"Toasted Coffee",
    //     sub_categ_priority:"Random",
    //     auto_rewards_info:{
    //         regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
    //         extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
    //         super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
    //     }
    // },
    // {   name:"Espresso Beans",
    //     aka:"Espresso Beans",
    //     price:"20",
    //     dominant_flavours:[{name:""}],
    //     pos_Position:"1",
    //     menu_Position:"1",
    //     order_options_type:"Merch",
    //     _id:'16',
    //     img_url:"xxxxxxxxxxxx",
    //     temp:"Hot Cold",
    //     main_categ: "Merch & Others",
    //     sub_categ:"Fried Coffee",
    //     sub_categ_priority:"Random",
    //     auto_rewards_info:{
    //         regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
    //         extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
    //         super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
    //     }
    // },
    {   name:"Espresso Coffee Beans Brazil",
        aka:"Brazil",
        price:"55",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Merch",
        _id:'17',
        img_url:"xxxxxxxxxxxx",
        temp:"Hot Cold",
        main_categ: "Merch & Others",
        sub_categ:"Fried Coffee",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    {   name:"Filter Coffee Beans Ethiopia 250g",
        aka:"Ethiopia",
        price:"55",
        dominant_flavours:[{name:""}],
        pos_Position:"1",
        menu_Position:"1",
        order_options_type:"Merch",
        _id:'17',
        img_url:"https://ik.imagekit.io/cwiiwa6f1/cafeMenuItems/beans%203.jpg?updatedAt=1731374701584",
        temp:"Hot Cold",
        main_categ: "Merch & Others",
        sub_categ:"Fried Coffee",
        sub_categ_priority:"Random",
        auto_rewards_info:{
            regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
            extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
            super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
        }
    },
    // {   name:"tumbler 500ml",
    //     aka:"tumbler 500ml",
    //     price:"20",
    //     dominant_flavours:[{name:""}],
    //     pos_Position:"1",
    //     menu_Position:"1",
    //     order_options_type:"Merch",
    //     _id:'18',
    //     img_url:"xxxxxxxxxxxx",
    //     temp:"Hot Cold",
    //     main_categ: "Merch & Others",
    //     sub_categ:"Fried Coffee",
    //     sub_categ_priority:"Random",
    //     auto_rewards_info:{
    //         regular_rewards:{reward_type:"Sip",reward_amt:1,reward_multiplier:1},
    //         extra_rewards:{reward_type:"Discount",reward_amt:10,reward_multiplier:1},
    //         super_rewards:{reward_type:"Buck",reward_amt:1,reward_multiplier:1},
    //     }
    // },
]
const categoriesList =[
    {   name:"Hot Coffee", _id:"1"},
    {   name:"Iced Coffee", _id:"2"},
    {   name:"Smoothies", _id:"3"},
    {   name:"Bowls", _id:"4"},
    {   name:"Toasted Coffee", _id:"5"},
]


  export {categoriesList,itemsList}