import axios from 'axios'

// const API_URL = 'http://localhost:5000/api/users/' || "https://crave-back-aa2b2df96a15.herokuapp.com/api/users"

const appStatus = "production"; // Or "development", set this variable as needed

const API_URL = appStatus === "production" 
  ? "https://crave-back-aa2b2df96a15.herokuapp.com/api/users/" 
  : "http://localhost:5000/api/users/";



// Register User
const registerUser = async (userData) => {
  const response = await axios.post(API_URL + 'register', userData);
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
  }
  return response.data;
};

// Login User
const loginUser = async (userData) => {
  const response = await axios.post(API_URL + 'login', userData);
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
  }
  return response.data;
};

// Logout User
const logoutUser = () => {
  localStorage.removeItem('user');
  axios.post(API_URL + 'logout');
};


// Get All users
const getUsers  = async (addNumber) => {
  console.log(addNumber)
  // const response = await axios.get(API_URL + 'get' , addNumber)
  const response = await axios({
    method: 'get',
    url: API_URL+'get',
    params: {addNumber: addNumber},
  })
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Get One user
const getOneUser  = async (user_id) => {
  const response = await axios.get(API_URL + 'getOne' , user_id)
  if (response.data) {
    console.log(response.data)
    // localStorage.setItem('user_called', JSON.stringify(response.data))
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Deduct Bucks
const deductBucks = async (_id, deductionAmount) => {
  try {
    const response = await axios.post(`${API_URL}deductBucks`, { _id, deductionAmount });
    return response.data;
  } catch (error) {
    console.error('Error deducting bucks:', error);
    throw error;
  }
};


// Get One user
const getMe  = async (user_id) => {
  const response = await axios.get(API_URL + 'getMe' , user_id)
  if (response.data) {
    console.log(response.data)
    // localStorage.setItem('user_called', JSON.stringify(response.data))
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Update One user
const updateUserInfo  = async (compData) => {
  // const response = await axios.put(API_URL + 'update' , compData)
  const response = await axios({
    method: 'put',
    url: API_URL+'update',
    params: compData,
  })
  if (response.data) {
    console.log(response.data)
    // localStorage.setItem('user_called', JSON.stringify(response.data))
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Delete One user
const deleteUser  = async (user_id) => {
  const response = await axios.delete(API_URL + 'delete' , user_id)
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data  
} 
// //Enter user Profile
// const userLogin = async (userData) => {
//   const response = await axios.post(API_URL + 'enteruser', userData)
//   console.log((userData)+ 'sent from service')
//   console.log((response.data)+ 'sent from service')

//   let comp = JSON.parse(localStorage.getItem('CompInProfile')) 
//   if (comp === null) {
//     if (response.data) {
//       localStorage.setItem('CompInProfile', JSON.stringify(response.data))
//     }
//   }
//   return response.data
// }

// Search and Fetch Users with Pagination
const searchAndFetchUsers = async (pageNumber, searchTerm) => {
  try {
    const response = await axios.get(API_URL + 'search', {
      params: { page: pageNumber, search: searchTerm }
    });
    if (response.data) {
      console.log(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const userAuthService = {
  registerUser,
  getUsers,
  searchAndFetchUsers, // Add the new function here
  getMe,
  getOneUser,
  updateUserInfo,
  deleteUser,
  deductBucks,
  loginUser,
  logoutUser,
};

export default userAuthService;
