import axios from 'axios'

// const API_URL = 'http://localhost:5000/api/menuItem/'
const appStatus = "production"; // Or "development", set this variable as needed

const API_URL = appStatus === "production" 
  ? "https://crave-back-aa2b2df96a15.herokuapp.com/api/menuItem/" 
  : "http://localhost:5000/api/menuItem/";
// Register item
const registerItem = async (itemData) => {
  const response = await axios.post(API_URL + 'register',itemData)
  if (response.data) {
    console.log(response.data)
    // localStorage.setItem('itemRegistered', JSON.stringify(response.data))
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
}
// Get All Companies
const getItems  = async (addNumber) => {
  console.log(addNumber)
  // const response = await axios.get(API_URL + 'get' , addNumber)
  const response = await axios({
    method: 'get',
    url: API_URL+'get',
    params: {addNumber: addNumber},
  })
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Get One item
const getOneItem  = async (comp_id) => {
  const response = await axios.get(API_URL + 'getOne' , comp_id)
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Update One item
const updateItemInfo  = async (compData) => {
  // const response = await axios.put(API_URL + 'update' , compData)
  const response = await axios({
    method: 'put',
    url: API_URL+'update',
    params: compData,
  })
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Delete One item
const deleteItem  = async (comp_id) => {
  const response = await axios.delete(API_URL + 'delete' , comp_id)
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data  
} 

const itemAuthService = {
  registerItem,  
  getItems,  
  getOneItem,  
  updateItemInfo,  
  deleteItem,  
}
export default itemAuthService
