import React from 'react';

const SmartphonePreview = () => {
  return (
    <div className="h-full min-w-[220px] flex items-center justify-center">
      <div className="h-[400px] w-[200px] border-[4px] rounded-3xl flex items-center justify-center overflow-hidden">
        <div className="h-full w-full backdrop-blur-sm bg-white/30">
          <p className="text-center text-black">Preview Content Here</p>
        </div>
      </div>
    </div>
  );
};

export default SmartphonePreview;
