import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

// Titles array
const titles = [
  "Transform Your Online Identity!",
  "Shape Your Online Presence!",
  "Craft Your Online Journey!",
  "Build Your Online Experience!"
];

// Variants for entry and exit animations
const variants = {
  enter: (direction) => ({
    opacity: 0,
    x: direction > 0 ? 100 : -100,
    scale: 0.8,
  }),
  center: {
    opacity: 1,
    x: 0,
    scale: 1,
  },
  exit: (direction) => ({
    opacity: 0,
    x: direction < 0 ? 100 : -100,
    scale: 0.8,
  })
};

const RotatingTitle = () => {
  const [[page, direction], setPage] = useState([0, 0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPage(([prevPage, prevDirection]) => [prevPage + 1, 1]);
    }, 10000); // Change title every 10 seconds

    return () => clearInterval(interval);
  }, []);

  // Function to cycle through titles
  const titleIndex = ((page % titles.length) + titles.length) % titles.length;

  return (
    <div className="flex justify-center items-center mt-5 mb-5">
      <motion.div
        key={page}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: 0.5 }}
        className="absolute text-slate-700 font-Amonk  text-2xl"
      >
        {titles[titleIndex]}
      </motion.div>
    </div>
  );
};

export default RotatingTitle;
