import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  creationData: {},
};

const company_creation_slice = createSlice({
  name: 'company_creation',
  initialState,
  reducers: {
    saveCreationData(state, action) {
      state.creationData = action.payload;
    },
    loadCreationData(state) {
      // Mock loading data; replace this with your actual data fetching logic
      state.creationData = state.creationData || {};
    },
  },
});

export const { saveCreationData, loadCreationData } = company_creation_slice.actions;

export default company_creation_slice.reducer;
