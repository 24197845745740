import React from 'react';

const LayoutColumn = () => {
  return (
    <div className="h-full w-full flex items-center justify-center
                    backdrop-blur-sm bg-white/30 rounded-lg">
      <h2 className="text-center text-xl">Right Column</h2>
    </div>
  );
};

export default LayoutColumn;
