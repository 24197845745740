import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import item_service from './item_service'
import toast from 'react-hot-toast';

// Get item from localStorage
const item = JSON.parse(localStorage.getItem('item'))

const appVersion = '1.0.0' // Your current app version

const initialState = {
  activeItemInView: {},
  newlyRegistered: {},
  updatedItem: {},
  oneItemCalled: {},
  fullItemList: [],
  ourCompany: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  appVersion, // Add app version to state
}

// Register item
export const registerItem = createAsyncThunk(
  'item_auth/registerItem',
  async (item, thunkAPI) => {
    try {
      return await item_service.registerItem(item)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Get All Items 
export const getItems = createAsyncThunk(
  'item_auth/getAllItems',
  async (item_info, thunkAPI) => {
    try {
      console.log("Getting Items")
      return await item_service.getItems(item_info)
    } catch (error) {
      console.log("Can't Get Items")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Get One item
export const getOneItem = createAsyncThunk(
  'item_auth/getOneItems',
  async (item_info, thunkAPI) => {
    try {
      return await item_service.getOneItem(item_info)
    } catch (error) {
      console.log("Can't Get Items")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Update item 
export const updateItemInfo = createAsyncThunk(
  'item_auth/updateItemInfo',
  async (item_info, thunkAPI) => {
    try {
      return await item_service.updateItemInfo(item_info)
    } catch (error) {
      console.log("Can't Update Items")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Delete item 
export const deleteItem = createAsyncThunk(
  'item_auth/deleteItem',
  async (item_info, thunkAPI) => {
    try {
      return await item_service.deleteItem(item_info)
    } catch (error) {
      console.log("Can't Delete item")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
export const item_slice = createSlice({
  name: 'item_auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    addItemToView(state, action) {
      console.log(action.payload);
      state.activeItemInView = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.newlyRegistered = action.payload;
        toast.success("Registered item Successfully");
      })
      .addCase(registerItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.newlyRegistered = null;
        toast.error("Item Registration Error");
      })
      .addCase(getItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.fullItemList = action.payload;
      })
      .addCase(getItems.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOneItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.oneItemCalled = action.payload;
        toast.success("Item Info Ready");
      })
      .addCase(getOneItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error("Getting Item Info Error");
      })
      .addCase(updateItemInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateItemInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updatedItem = action.payload;
        const newFullList = state.fullItemList.map(comp =>
          comp._id === action.payload._id ? action.payload : comp);
        state.fullItemList = newFullList;
        toast.success("Item Updated Successfully");
      })
      .addCase(updateItemInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error("Item Update Error");
      })
      .addCase(deleteItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteItem.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success("Item Deleted Successfully");
      })
      .addCase(deleteItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error("Item Delete Error");
      });
  },
});

export const { reset, addItemToView } = item_slice.actions;
export default item_slice.reducer;
