import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userBoughtFromUsService from './userBoughtFromUs_service';
const appVersion = '1.0.0' // Your current app version

const initialState = {
  userBoughtFromUs: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
  appVersion, // Add app version to state
};

// Create or Update User Bought From Us
export const createOrUpdateUserBoughtFromUs = createAsyncThunk(
  'userBoughtFromUs/createOrUpdate',
  async (data, thunkAPI) => {
    try {
      return await userBoughtFromUsService.createOrUpdateUserBoughtFromUs(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get User Bought From Us
export const getUserBoughtFromUs = createAsyncThunk(
  'userBoughtFromUs/get',
  async ({ user_id, company_id }, thunkAPI) => {
    try {
      return await userBoughtFromUsService.getUserBoughtFromUs(user_id, company_id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const userBoughtFromUsSlice = createSlice({
  name: 'userBoughtFromUs',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrUpdateUserBoughtFromUs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOrUpdateUserBoughtFromUs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userBoughtFromUs = action.payload;
      })
      .addCase(createOrUpdateUserBoughtFromUs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUserBoughtFromUs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserBoughtFromUs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userBoughtFromUs = action.payload;
      })
      .addCase(getUserBoughtFromUs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = userBoughtFromUsSlice.actions;
export default userBoughtFromUsSlice.reducer;
