const dummyBranch = {
  company_id: "company123",
  branch_id: "branch123",
  x_id: "brbranch123",
  name: "BrewSpot Coffee - Downtown",
  public_name: "BrewSpot Coffee Downtown",
  is_logged_in: "False",
  email: "downtown@brewspotcoffee.com",
  password: "default_branch_password",
  lvl_1_pass: "branch_lvl1_pass",
  lvl_2_pass: "branch_lvl2_pass",
  lvl_3_pass: "branch_lvl3_pass",
  instagram: "brewspot_downtown",
  iframe: "iframe_code_here",
  tell: "123-456-7890",
  whatsapp: "whatsapp_number_here",
  profile_pic_url: "https://example.com/images/brewspot-downtown-logo.png",
  timings: {
    Monday: "7am - 9pm",
    Tuesday: "7am - 9pm",
    Wednesday: "7am - 9pm",
    Thursday: "7am - 9pm",
    Friday: "7am - 9pm",
    Saturday: "8am - 10pm",
    Sunday: "8am - 8pm",
  },
  menu_info: {
    season_style: "Seasonal",
    main_categs_list: [
      { _id: "1", name: "Coffee" },
      { _id: "2", name: "Refreshers" },
      { _id: "3", name: "Food" },
      { _id: "4", name: "Desserts" },
      { _id: "5", name: "Merch & Others" }
    ]
  },
  status: "Not Reached Out Yet",
  instagram_dms: [],
  calls: [],
  emails: [],
  item_order_options: "Crave Default",
  whatsApps: [],
  walk_ins: [],
  keywords_list: ["Specialty Coffee", "Pastries", "Free Wi-Fi"],
  open_status: "Open",
  amenities: {
    wifi: "Yes",
    indoor_outdoor: "Indoor & Outdoor Seating",
    Washroom: "Yes",
    Pets: "Yes",
    dine_in: "Dine-in & Takeout"
  },
  rating: {
    general: "Excellent Service!",
    total_votes: 80, // Total number of overall ratings or reviews
    food_quality: {
      description: "Delicious and freshly made",
      total_thumbs: 100, // Total thumbs in this category
      up_thumbs: 90, // Positive thumbs
      down_thumbs: 10 // Negative thumbs
    },
    service_quality: {
      description: "Friendly and attentive staff",
      total_thumbs: 90, // Total thumbs in this category
      up_thumbs: 80, // Positive thumbs
      down_thumbs: 10 // Negative thumbs
    },
    value_for_money: {
      description: "Affordable and worthwhile",
      total_thumbs: 85, // Total thumbs in this category
      up_thumbs: 70, // Positive thumbs
      down_thumbs: 15 // Negative thumbs
    }
  },
  footer_copyright: "© 2024 BrewSpot Coffee",
};

export default dummyBranch;
