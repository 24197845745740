import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import mission_service from './mission_service'
import toast from 'react-hot-toast';

// Get Mission from localStorage
const mission = JSON.parse(localStorage.getItem('mission'))

const initialState = {
  newlyRegistered:{},
  updatedMission:{},
  oneMissionCalled:{},
  fullMissionList:[],
  ourCompany:{},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Register Mission
export const registerMission = createAsyncThunk(
  'mission_auth/registerMission',
  async (mission, thunkAPI) => {
    try {
      return await mission_service.registerMission(mission)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Get All Missions 
export const getMissions = createAsyncThunk(
  'mission_auth/getAllMissions',
  async (mission_info, thunkAPI) => {
    try {
      console.log("Getting Missions")
      return await mission_service.getMissions(mission_info)
    } catch (error) {
      console.log("Can't Get Missions")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Get One Mission
export const getOneMission = createAsyncThunk(
  'mission_auth/getOneMission',
  async (mission_info, thunkAPI) => {
    try {

      return await mission_service.getOneMission(mission_info)
    } catch (error) {
      console.log("Can't Get Mission")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Update Mission 
export const updateMissionInfo = createAsyncThunk(
  'mission_auth/updateMissionInfo',
  async (mission_info, thunkAPI) => {
    try {

      return await mission_service.updateMissionInfo(mission_info)
    } catch (error) {
      console.log("Can't Update Missions")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Delete Mission 
export const deleteMission = createAsyncThunk(
  'mission_auth/deleteMission',
  async (mission_info, thunkAPI) => {
    try {

      return await mission_service.deleteMission(mission_info)
    } catch (error) {
      console.log("Can't Delete mission")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const quest_slice = createSlice({
  name: 'mission_auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerMission.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerMission.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.newlyRegistered = action.payload
        toast.success("Registered Mission Successfully")
      })
      .addCase(registerMission.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.newlyRegistered = null
        toast.error("Mission Registration Error")
      })


      .addCase(getMissions.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMissions.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.fullMissionList = action.payload
      })
      .addCase(getMissions.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })


      .addCase(getOneMission.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOneMission.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.oneMissionCalled = action.payload
        toast.success("Mission Info Ready")
      })
      .addCase(getOneMission.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Getting Mission Info Error")
      })


      .addCase(updateMissionInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateMissionInfo.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.updatedMission = action.payload
        const newFullList = fullMissionList;
        newFullList.map(comp => comp._id === updatedMission._id ? updatedMission : comp);
        state.fullMissionList = newFullList;
        toast.success("Mission Updated Successfully")
      })
      .addCase(updateMissionInfo.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Mission Update Error")
      })

      .addCase(deleteMission.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteMission.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        toast.success("Mission Deleted Successfully")
      })
      .addCase(deleteMission.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Mission Delete Error")
      })

    
    },
  })

export const { reset } = quest_slice.actions
export default quest_slice.reducer
