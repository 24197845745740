import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import template_item_service from './template_item_service'
import toast from 'react-hot-toast';

// Get item from localStorage
const item = JSON.parse(localStorage.getItem('item'))
const appVersion = '1.0.0' // Your current app version

const initialState = {
  activeItemInView:{},
  newlyRegistered:{},
  updatedItem:{},
  oneItemCalled:{},
  fullItemsTemplateList:[],
  ourCompany:{},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  appVersion, // Add app version to state
}

// Register item
export const registerTemplateItem = createAsyncThunk(
  'template_item_auth/registerTemplateItem',
  async (item, thunkAPI) => {
    try {
      return await template_item_service.registerTemplateItem(item)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Get All Items 
export const getTemplateItems = createAsyncThunk(
  'template_item_auth/getAllItems',
  async (item_info, thunkAPI) => {
    try {
      console.log("Getting Items")
      return await template_item_service.getTemplateItems(item_info)
    } catch (error) {
      console.log("Can't Get Items")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Get One item
export const getOneTemplateItem = createAsyncThunk(
  'template_item_auth/getOneTemplateItem',
  async (item_info, thunkAPI) => {
    try {

      return await template_item_service.getOneTemplateItem(item_info)
    } catch (error) {
      console.log("Can't Get Items")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Update item 
export const updateTemplateItemInfo = createAsyncThunk(
  'template_item_auth/updateTemplateItemInfo',
  async (item_info, thunkAPI) => {
    try {

      return await template_item_service.updateTemplateItemInfo(item_info)
    } catch (error) {
      console.log("Can't Update Items")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Delete item 
export const deleteTemplateItem = createAsyncThunk(
  'template_item_auth/deleteTemplateItem',
  async (item_info, thunkAPI) => {
    try {

      return await template_item_service.deleteTemplateItem(item_info)
    } catch (error) {
      console.log("Can't Delete item")
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const item_slice = createSlice({
  name: 'template_item_auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
    addItemToView(state, action) {
console.log(action.payload)
      state.activeItemInView = action.payload;
      // toast.success("Order added to tray", {
      //   position: "bottom-left",
      // });
      // toast.info("Order added to tray", {
      //   position: "bottom-left",
      // });
      // localStorage.setItem("trayItems", JSON.stringify(state.trayItems));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerTemplateItem.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerTemplateItem.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.newlyRegistered = action.payload
        toast.success("Registered item Successfully")
      })
      .addCase(registerTemplateItem.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.newlyRegistered = null
        toast.error("item Registration Error")
      })


      .addCase(getTemplateItems.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTemplateItems.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.fullItemsTemplateList = action.payload
      })
      .addCase(getTemplateItems.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })


      .addCase(getOneTemplateItem.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOneTemplateItem.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.oneItemCalled = action.payload
        toast.success("item Info Ready")
      })
      .addCase(getOneTemplateItem.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("Getting item Info Error")
      })


      .addCase(updateTemplateItemInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateTemplateItemInfo.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.updatedItem = action.payload
        const newFullList = fullItemList;
        newFullList.map(comp => comp._id === updatedItem._id ? updatedItem : comp);
        state.fullItemList = newFullList;
        toast.success("item Updated Successfully")
      })
      .addCase(updateTemplateItemInfo.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("item Update Error")
      })

      .addCase(deleteTemplateItem.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteTemplateItem.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        toast.success("item Deleted Successfully")
      })
      .addCase(deleteTemplateItem.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error("item Delete Error")
      })

      
    },
  })

export const { reset, addItemToView } = item_slice.actions
export default item_slice.reducer
