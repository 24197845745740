import { createSlice } from '@reduxjs/toolkit'
const appVersion = '1.0.0' // Your current app version

const initialState = {
  trayItems: [],
  active_Bucks_Discount: null,
  active_PowerUp: null,
  appVersion, // Add app version to state
};

const traySlice = createSlice({
  name: "tray",
  initialState,
  reducers: {
    addToTray(state, action) {
      console.log(action.payload);
      const newItem = action.payload;
    
      let existingItem = state.trayItems.find((item) => 
        item._id === newItem._id && 
        item.size === newItem.size && 
        item.temp === newItem.temp && 
        item.sweet === newItem.sweet && 
        item.milk === newItem.milk && 
        item.intensity === newItem.intensity
      );
    
      if (existingItem) {
        console.log("item exists in Tray with same properties");
        const newState = state.trayItems.map(item => {
          if (item._id === newItem._id && 
              item.size === newItem.size && 
              item.temp === newItem.temp && 
              item.sweet === newItem.sweet && 
              item.milk === newItem.milk && 
              item.intensity === newItem.intensity) {
            console.log("Exact Same");
            return {
              ...item,
              amt: item.amt + newItem.amt,
            };
          }
          return item;
        });
        state.trayItems = newState;
      } else {
        console.log("Different Kind");
        state.trayItems.push(newItem);
      }
    },
    
    deductAmt(state, action) {
      console.log(action.payload)
      let existingItem = state.trayItems.find((item) => item._id === action.payload);
      if (existingItem) {
        const newTray = state.trayItems.map(item => {
          if (item._id === action.payload) { 
            if (item.amt < 2) {
              return null; // Mark item for removal
            } else {
              return { ...item, amt: item.amt - 1 };
            }
          }
          return item;
        }).filter(item => item !== null); // Filter out null items
        state.trayItems = newTray;
      }
    },
    
    updateTray(state, action) {
      state.trayItems = action.payload;
    },
    
    emptyTray(state) {
      state.trayItems = [];
      state.active_Bucks_Discount = null;
      state.active_PowerUp = null;
    },
    
    addAmt(state, action) {
      console.log(action.payload)
      let existingItem = state.trayItems.find((item) => item._id === action.payload);
      if (existingItem) {
        const newTray = state.trayItems.map(item => {
          if (item._id === action.payload) { 
            return { ...item, amt: item.amt + 1 };
          }
          return item;
        });
        state.trayItems = newTray;
      }
    },
    
    set_Active_Bucks_Discount(state, action) { 
      state.active_Bucks_Discount = action.payload; 
    },
    
    clear_Active_Bucks_Discount(state) { 
      state.active_Bucks_Discount = null; 
    },
    
    setActivePowerUp(state, action) { 
      state.active_PowerUp = action.payload; 
    },
    
    clearActivePowerUp(state) { 
      state.active_PowerUp = null; 
    },
  },
});

export const { 
  addToTray, 
  addAmt, 
  deductAmt, 
  updateTray, 
  emptyTray, 
  set_Active_Bucks_Discount, 
  clear_Active_Bucks_Discount, 
  setActivePowerUp, 
  clearActivePowerUp 
} = traySlice.actions;

export default traySlice.reducer;
