import axios from 'axios';

// const API_URL = 'http://localhost:5000/api/orders/';
const appStatus = "production"; // Or "development", set this variable as needed

const API_URL = appStatus === "production" 
  ? "https://crave-back-aa2b2df96a15.herokuapp.com/api/orders/" 
  : "http://localhost:5000/api/orders/";
// Register order
const registerOrder = async (orderData) => {
  try {
    const response = await axios.post(API_URL + 'registerOrder', orderData);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error registering order:", error.response?.data || error.message);
    throw error;
  }
};

const getAllOrders = async (user_id, year, month) => {
  try {
    const response = await axios.get(API_URL + 'getAllOrders', { params: { user_id, year, month } });
    console.log("Orders fetched from API:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error getting orders:", error.response?.data || error.message);
    throw error;
  }
};



// Get One Order
const getOneOrder = async (comp_id) => {
  try {
    const response = await axios.get(API_URL + 'getOne', { params: { comp_id } });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error getting one order:", error.response?.data || error.message);
    throw error;
  }
};

// Update One Order
const updateOrderInfo = async (compData) => {
  try {
    const response = await axios.put(API_URL + 'updateOrderInfo', compData);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating order info:", error.response?.data || error.message);
    throw error;
  }
};

// Delete One Order
const deleteOrder = async (comp_id) => {
  try {
    const response = await axios.delete(API_URL + 'delete', { params: { comp_id } });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleting order:", error.response?.data || error.message);
    throw error;
  }
};

const orderAuthService = {
  registerOrder,
  getAllOrders,
  getOneOrder,
  updateOrderInfo,
  deleteOrder,
};

export default orderAuthService;
