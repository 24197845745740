import React, { useState } from 'react';
import { AnimatePresence, motion } from "framer-motion";
import { FiAlertCircle, FiX } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { registerBranch } from '../../../../../features/branches/branch_slice';
import dummyBranch from './testBranch'
import { itemsList } from '../../../carousel_Info';

const DetailsColumn = () => {
  const dispatch = useDispatch();
  const comp_local = JSON.parse(localStorage.getItem('company_local'));

  const [inputData, setInputData] = useState({
    company_id: "",
    branch_id: "",
    x_id: "",
    is_logged_in: "",
    email: "",
    password: "",
    name: "",
    public_name: "",
    lvl_1_pass: "",
    lvl_2_pass: "",
    lvl_3_pass: "",
    instagram: "",
    iframe: "",
    tell: "",
    whatsapp: "",
    profile_pic_url: "",
    timings: {},
    menu_info: {},
    company_name: "",
    company_type: "",
    country: "",
    city: "",
    admin_limit: "",
    enter_home_Loading: "Default",
    enter_menu_loading: "Default",
    enter_challenge_loading: "Default",
    paid_for_cart: "No",
    return_policy: "",
    exchange_policy: "",
    registered_by: "Within Reach",
    monday: "8am - 10pm",
    tuesday: "8am - 10pm",
    wednesday: "8am - 10pm",
    thursday: "8am - 10pm",
    friday: "8am - 10pm",
    saturday: "8am - 10pm",
    sunday: "8am - 10pm"
  });

  const { currentCompany } = useSelector((state) => state.company_auth);

  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [openStatus, setOpenStatus] = useState("Open");
  const [isKeywordsOpen, setIsKeywordsOpen] = useState(true);
  const [isAmenitiesOpen, setIsAmenitiesOpen] = useState(true);

  const onChange = (e) => {
    setInputData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const toggleKeyword = (word) => {
    setSelectedKeywords((prev) => 
      prev.includes(word) ? prev.filter((kw) => kw !== word) : [...prev, word]
    );
  };

  const toggleAmenity = (amenity) => {
    setSelectedAmenities((prev) => 
      prev.includes(amenity) ? prev.filter((am) => am !== amenity) : [...prev, amenity]
    );
  };

  const toggleOpenStatus = () => {
    setOpenStatus((prev) => (prev === "Open" ? "Closed" : "Open"));
  };

  const createBranch = () => {
    const firstDate = new Date();

    let branchData = {
      company_id: currentCompany == null || Object.keys(currentCompany).length === 0 ? comp_local._id : currentCompany._id,
      branch_id: uuidv4(),
      x_id: `br${uuidv4()}`,
      name: inputData.name || dummyBranch.name,
      public_name: inputData.public_name || dummyBranch.public_name,
      is_logged_in: "False",
      email: inputData.email || dummyBranch.email,
      item_order_options: dummyBranch.item_order_options,
      password: inputData.password || dummyBranch.password,
      lvl_1_pass: inputData.lvl_1_pass || "xx",
      lvl_2_pass: inputData.lvl_2_pass || "xx",
      lvl_3_pass: inputData.lvl_3_pass || "xx",
      instagram: inputData.instagram || "Company0",
      iframe: inputData.iframe || "111",
      tell: inputData.tell || "111",
      whatsapp: inputData.whatsapp || "XXxxxxx",
      profile_pic_url: inputData.profile_pic_url || "XXxxxxx",
      timings: {
        Monday: inputData.monday,
        Tuesday: inputData.tuesday,
        Wednesday: inputData.wednesday,
        Thursday: inputData.thursday,
        Friday: inputData.friday,
        Saturday: inputData.saturday,
        Sunday: inputData.sunday
      },
      menu_info: dummyBranch.menu_info || {},
      menu_items: itemsList,
      status: "Not Reached Out Yet",
      instagram_dms: [],
      calls: [],
      emails: [],
      whatsApps: [],
      walk_ins: [],
      keywords_list: selectedKeywords.length > 0 ? selectedKeywords : [],
      open_status: openStatus,
      amenities: selectedAmenities.length > 0 ? selectedAmenities : [],
    };
    
    console.log(branchData)
    dispatch(registerBranch(branchData));
  };

  return (
<div className="h-full w-full flex items-center justify-center
                    backdrop-blur-sm bg-white/30 rounded-lg">
      <div className="h-full w-full flex flex-col gap-4 p-4 
                      text-black overflow-y-scroll">
        <div className="flex flex-col">
            <label className='text-sm'>
              Branch Name
              <input
                type='text'
                id='name'
                name='name'
                value={inputData.name}
                placeholder={dummyBranch.name}
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Public Name
              <input
                type='text'
                id='public_name'
                name='public_name'
                value={inputData.public_name}
                placeholder={dummyBranch.public_name}
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Email
              <input
                type='email'
                id='email'
                name='email'
                value={inputData.email}
                placeholder={dummyBranch.email}
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Password
              <input
                type='password'
                id='password'
                name='password'
                value={inputData.password}
                placeholder={dummyBranch.password}
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Level 1 Password
              <input
                type='password'
                id='lvl_1_pass'
                name='lvl_1_pass'
                value={inputData.lvl_1_pass}
                placeholder='xx'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
            Level 2 Password
              <input
                type='password'
                id='lvl_2_pass'
                name='lvl_2_pass'
                value={inputData.lvl_2_pass}
                placeholder='xx'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Level 3 Password
              <input
                type='password'
                id='lvl_3_pass'
                name='lvl_3_pass'
                value={inputData.lvl_3_pass}
                placeholder='xx'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Instagram
              <input
                type='text'
                id='instagram'
                name='instagram'
                value={inputData.instagram}
                placeholder='Company0'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Iframe
              <input
                type='text'
                id='iframe'
                name='iframe'
                value={inputData.iframe}
                placeholder='111'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Main Phone Number
              <input
                type='text'
                id='tell'
                name='tell'
                value={inputData.tell}
                placeholder={dummyBranch.tell}
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              WhatsApp
              <input
                type='text'
                id='whatsapp'
                name='whatsapp'
                value={inputData.whatsapp}
                placeholder='XXxxxxx'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Profile Picture URL
              <input
                type='text'
                id='profile_pic_url'
                name='profile_pic_url'
                value={inputData.profile_pic_url}
                placeholder='XXxxxxx'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Open Status
              <button
                className={`p-2 rounded-md text-black ${openStatus === 'Open' ? 'bg-green-500' : 'bg-red-500'}`}
                onClick={toggleOpenStatus}
              >
                {openStatus}
              </button>
            </label>
            <div className="flex flex-col">
              <div className='flex justify-between'>
                <label className="text-sm">Keywords List</label>
                {isKeywordsOpen ? (
                  <FiX className="text-black cursor-pointer" onClick={() => setIsKeywordsOpen(false)} />
                ) : (
                  <div className='h-10 w-10 flex justify-center text-white rounded-full border-2 mb-2 cursor-pointer border-white' onClick={() => setIsKeywordsOpen(true)}>...</div>
                )}
              </div>
              {isKeywordsOpen && (
                <div className="flex flex-wrap mt-2">
                  {["coffee", "espresso", "latte", "cappuccino", "brew"].map((keyword, index) => (
                    <div
                      key={index}
                      className={`p-2 rounded-full border-2 mr-2 mb-2 cursor-pointer ${selectedKeywords.includes(keyword) ? 'border-green-500' : 'border-white'}`}
                      onClick={() => toggleKeyword(keyword)}
                    >
                      {keyword}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex flex-col">
              <div className='flex justify-between'>
                <label className="text-sm">Amenities</label>
                {isAmenitiesOpen ? (
                  <FiX className="text-black cursor-pointer" onClick={() => setIsAmenitiesOpen(false)} />
                ) : (
                  <div className='h-10 w-10 flex justify-center text-white rounded-full border-2 mb-2 cursor-pointer border-white' onClick={() => setIsAmenitiesOpen(true)}>...</div>
                )}
              </div>
              {isAmenitiesOpen && (
                <div className="flex flex-wrap mt-2">
                  {["Wifi", "Toilet", "Parking", "Indoor Seating", "Outdoor Seating"].map((amenity, index) => (
                    <div
                      key={index}
                      className={`p-2 rounded-full border-2 mr-2 mb-2 cursor-pointer ${selectedAmenities.includes(amenity) ? 'border-green-500' : 'border-white'}`}
                      onClick={() => toggleAmenity(amenity)}
                    >
                      {amenity}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Monday
              <input
                type='text'
                id='monday'
                name='monday'
                value={inputData.monday}
                placeholder='8am - 10pm'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Tuesday
              <input
                type='text'
                id='tuesday'
                name='tuesday'
                value={inputData.tuesday}
                placeholder='8am - 10pm'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Wednesday
              <input
                type='text'
                id='wednesday'
                name='wednesday'
                value={inputData.wednesday}
                placeholder='8am - 10pm'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Thursday
              <input
                type='text'
                id='thursday'
                name='thursday'
                value={inputData.thursday}
                placeholder='8am - 10pm'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Friday
              <input
                type='text'
                id='friday'
                name='friday'
                value={inputData.friday}
                placeholder='8am - 10pm'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Saturday
              <input
                type='text'
                id='saturday'
                name='saturday'
                value={inputData.saturday}
                placeholder='8am - 10pm'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <div className="flex flex-col">
            <label className='text-sm'>
              Sunday
              <input
                type='text'
                id='sunday'
                name='sunday'
                value={inputData.sunday}
                placeholder='8am - 10pm'
                onChange={onChange}
                className='p-2 rounded-md text-black border-2 border-white backdrop-blur-sm bg-white/10 mt-1'
              />
            </label>
            </div>
            <button className="btn btn-active btn-primary mt-4" onClick={createBranch}>Create</button>
            </div>
            </div>
            </div>
  );
};

export default DetailsColumn;
