import axios from 'axios'
// const production_state = "Dev"
// const API_URL = production_state==="Dev"?'api/branches/':
// 'https://crave-back-aa2b2df96a15.herokuapp.com/api/branches/'

const appStatus = "production"; // Or "development", set this variable as needed

const API_URL = appStatus === "production" 
  ? "https://crave-back-aa2b2df96a15.herokuapp.com/api/branches/" 
  : "http://localhost:5000/api/branches/";
// Register branch
const registerBranch = async (branchData) => {
  const response = await axios.post(API_URL + 'register',branchData)
  if (response.data) {
    console.log(response.data)
    localStorage.removeItem("branch_local");
    localStorage.setItem('branch_local', JSON.stringify(response.data))
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
}
// Get All branches
const getBranches  = async (addNumber) => {
  console.log(addNumber)
  // const response = await axios.get(API_URL + 'get' , addNumber)
  const response = await axios({
    method: 'get',
    url: API_URL+'getBranches',
    params: addNumber,
  })
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
}

// Get One branch
const getOneBranch  = async (comp_id) => {
  const response = await axios.get(API_URL + 'getOne' , comp_id)
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Update One branch
const updateBranchItems  = async (branchData) => {
  
  const response = await axios.put(API_URL + 'updateBranchItems' , branchData)
  // const response = await axios({
  //   method: 'put',
  //   url: API_URL+'updateBranchItems',
  //   params: branchData,
  // })
  if (response.data) {
    localStorage.removeItem("branch_local");
    localStorage.setItem('branch_local', JSON.stringify(response.data))
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Update One branch
const updateBranchInfo  = async (compData) => {
  // const response = await axios.put(API_URL + 'update' , compData)
  const response = await axios({
    method: 'put',
    url: API_URL+'update',
    params: compData,
  })
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Delete One branch
const deleteBranch  = async (comp_id) => {
  const response = await axios.delete(API_URL + 'delete' , comp_id)
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data  
} 
//Enter branch Profile
const branchLogin = async (branchData) => {
  const response = await axios.post(API_URL + 'enterbranch', branchData)
  console.log((branchData)+ 'sent from service')
  console.log((response.data)+ 'sent from service')

  let comp = JSON.parse(localStorage.getItem('CompInProfile')) 
  if (comp === null) {
    if (response.data) {
      localStorage.setItem('CompInProfile', JSON.stringify(response.data))
    }
  }
  return response.data
}

const branchAuthService = {
  registerBranch,  
  getBranches,  
  getOneBranch,  
  updateBranchItems,  
  updateBranchInfo,  
  deleteBranch,
}
export default branchAuthService
