import axios from 'axios';
// const API_URL = '/api/powerUpPieces/';
const appStatus = "production"; // Or "development", set this variable as needed

const API_URL = appStatus === "production" 
  ? "https://crave-back-aa2b2df96a15.herokuapp.com/api/powerUpPieces/" 
  : "http://localhost:5000/api/powerUpPieces/";
// Register PowerUpPiece
const registerPowerUpPiece = async (powerUpPieceData) => {
  try {
    const response = await axios.post(API_URL + 'create', powerUpPieceData);
    return response.data;
  } catch (error) {
    console.error('Error registering powerup piece:', error);
    throw error.response?.data?.message || 'An error occurred while registering the powerup piece';
  }
};

// Get PowerUpPiece by ID
const getPowerUpPiece = async (id) => {
  try {
    const response = await axios.get(`${API_URL}getOne/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching powerup piece:', error);
    throw error.response?.data?.message || 'An error occurred while fetching the powerup piece';
  }
};

// Get All PowerUpPieces
const getAllPowerUpPieces = async () => {
  try {
    const response = await axios.get(API_URL + 'getAll');
    return response.data;
  } catch (error) {
    console.error('Error fetching all powerup pieces:', error);
    throw error.response?.data?.message || 'An error occurred while fetching all powerup pieces';
  }
};

// Update PowerUpPiece by ID
const updatePowerUpPiece = async (id, powerUpPieceData) => {
  try {
    const response = await axios.put(`${API_URL}update/${id}`, powerUpPieceData);
    return response.data;
  } catch (error) {
    console.error('Error updating powerup piece:', error);
    throw error.response?.data?.message || 'An error occurred while updating the powerup piece';
  }
};

// Get PowerUpPiece Group by Original PowerUp ID
const getPowerUpPieceGroup = async (original_powerup_id) => {
  try {
    const response = await axios.get(`${API_URL}group`, { params: { original_powerup_id } });
    return response.data;
  } catch (error) {
    console.error('Error fetching powerup piece group:', error);
    throw error.response?.data?.message || 'An error occurred while fetching the powerup piece group';
  }
};

// Delete PowerUpPiece by ID
const deletePowerUpPiece = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting powerup piece:', error);
    throw error.response?.data?.message || 'An error occurred while deleting the powerup piece';
  }
};

// Export all services
const powerUpPieceService = {
  registerPowerUpPiece,
  getPowerUpPiece,
  getAllPowerUpPieces,
  updatePowerUpPiece,
  getPowerUpPieceGroup,
  deletePowerUpPiece
};

export default powerUpPieceService;
