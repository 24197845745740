import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import powerUpService from './powerUp_service';

const appVersion = '1.0.0' // Your current app version

const initialState = {
  currentPowerUp: {},
  subCategories: [],
  powerUps: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
  appVersion, // Add app version to state
};


// Async Thunks
export const registerPowerUp = createAsyncThunk('powerUps/register', async (powerUpData, thunkAPI) => {
  try {
    return await powerUpService.registerPowerUp(powerUpData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getPowerUp = createAsyncThunk('powerUps/getOne', async (id, thunkAPI) => {
  try {
    return await powerUpService.getPowerUp(id);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getPowerUpGroup = createAsyncThunk('powerUps/group', async (sub_category, thunkAPI) => {
  try {
    return await powerUpService.getPowerUpGroup(sub_category);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// ........
export const fetchSubCategories = createAsyncThunk('powerUps/fetchSubCategories', async (_, thunkAPI) => {
  try {
    return await powerUpService.getSubCategories();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Fetch PowerUp Group by Sub-Category and Name
// Fetch PowerUp Group by Sub-Category and Name
export const fetchPowerUpGroup = createAsyncThunk('powerUps/fetchPowerUpGroup', async ({ subCategory, searchTerm }, thunkAPI) => {
  try {
    return await powerUpService.getPowerUpGroupBySubCategoryAndName(subCategory, searchTerm);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// ........

// export const fetchPowerUpGroup = createAsyncThunk('powerUps/group', async (sub_category, thunkAPI) => {
//   try {
//     return await powerUpService.getPowerUpGroup(sub_category);
//   } catch (error) {
//     return thunkAPI.rejectWithValue(error.response.data);
//   }
// });

export const giveUserPowerUp = createAsyncThunk('powerUps/give', async ({ userId, powerUpId }, thunkAPI) => {
  try {
    return await powerUpService.givePowerUpToUser(userId, powerUpId);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const getAllPowerUps = createAsyncThunk('powerUps/getAll', async (_, thunkAPI) => {
  try {
    return await powerUpService.getAllPowerUps();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updatePowerUp = createAsyncThunk('powerUps/update', async (data, thunkAPI) => {
  const { id, powerUpData } = data;
  try {
    return await powerUpService.updatePowerUp(id, powerUpData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updatePowerUpGroup = createAsyncThunk('powerUps/updateGroup', async (data, thunkAPI) => {
  const { sub_category, updates } = data;
  try {
    return await powerUpService.updatePowerUpGroup(sub_category, updates);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deletePowerUp = createAsyncThunk('powerUps/delete', async (id, thunkAPI) => {
  try {
    return await powerUpService.deletePowerUp(id);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deletePowerUpGroup = createAsyncThunk('powerUps/deleteGroup', async (sub_category, thunkAPI) => {
  try {
    return await powerUpService.deletePowerUpGroup(sub_category);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Slice
const powerUpSlice = createSlice({
  name: 'powerUps',
  initialState,
  reducers: {
    reset: (state) => initialState, 
    setCurrentPowerUp: (state, action) => { state.currentPowerUp = action.payload; },    
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerPowerUp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerPowerUp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.powerUps.push(action.payload);
      })
      .addCase(registerPowerUp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPowerUp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPowerUp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.powerUp = action.payload;
      })
      .addCase(getPowerUp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPowerUpGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPowerUpGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.powerUps = action.payload;
      })
      .addCase(getPowerUpGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllPowerUps.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPowerUps.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.powerUps = action.payload;
      })
      .addCase(getAllPowerUps.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updatePowerUp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePowerUp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.powerUps.findIndex((powerUp) => powerUp._id === action.payload._id);
        if (index !== -1) {
          state.powerUps[index] = action.payload;
        }
      })
      .addCase(updatePowerUp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updatePowerUpGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePowerUpGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Update logic for group update if needed
      })
      .addCase(updatePowerUpGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deletePowerUp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePowerUp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.powerUps = state.powerUps.filter((powerUp) => powerUp._id !== action.payload.id);
      })
      .addCase(deletePowerUp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deletePowerUpGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePowerUpGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Update logic for group delete if needed
      })
    .addCase(deletePowerUpGroup.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    })
    .addCase(fetchSubCategories.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchSubCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.subCategories = action.payload;
    })
    .addCase(fetchSubCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    })
            .addCase(fetchPowerUpGroup.pending, (state) => {
              state.isLoading = true;
            })
            .addCase(fetchPowerUpGroup.fulfilled, (state, action) => {
              state.isLoading = false;
              state.isSuccess = true;
              state.powerUps = action.payload;
            })
            .addCase(fetchPowerUpGroup.rejected, (state, action) => {
              state.isLoading = false;
              state.isError = true;
              state.message = action.payload;
            });
      
          }
        });

export const { reset, setCurrentPowerUp } = powerUpSlice.actions;
export default powerUpSlice.reducer;
