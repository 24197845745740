import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import users_slice from '../features/users/user_slice';
import branchReducer from '../features/branches/branch_slice';
import company_slice from '../features/companies/company_slice';
import item_slice from '../features/menu_items/item_slice';
import template_item_slice from '../features/menu_items/template_item_slice';
import orders_slice from '../features/orders/order_slice';
import quest_slice from '../features/quests/quest_slice';
import mission_slice from '../features/missions/mission_slice';
import comment_slice from '../features/comments/comment_slice';
import trayReducer from '../features/punchTray/traySlice';
import perkReducer from '../features/perks/perkSlice';
import powerUps_slice from '../features/powerups/powerUp_slice';
import powerUpsPieces_slice from '../features/powerUpPieces/powerUpPieceService_Slice';
import crave_store_slice from '../features/craveStore/crave_store_slice';
import userBoughtFromUs_slice from '../features/userBoughtFromUs/userBoughtFromUs_slice';
import user_friends_slice from '../features/usersFriends/user_friends_slice';
import company_creation_slice from '../features/company_creation/company_creation_slice';

const appVersion = '1.0.3'; // Update this version to trigger state reset

const persistConfig = {
  key: 'root',
  version: 1, // Update version number when making breaking changes
  storage,
  migrate: async (state) => {
    if (state && state._persist.version !== persistConfig.version) {
      // Clear persisted state when version changes
      console.log('Version mismatch. Clearing persisted state...');
      return {
        // Return initial state structure here
        users_auth: {},
        branch_auth: {},
        company_auth: {},
        item_auth: {},
        template_item_auth: {},
        order_auth: {},
        quest_auth: {},
        mission_auth: {},
        comment_auth: {},
        tray: {
          trayItems: [],
          active_Bucks_Discount: null,
          active_PowerUp: null,
          appVersion
        },
        perks: {},
        powerUps: {},
        powerUpPieces: {},
        craveStore: {},
        userBoughtFromUs: {},
        company_creation: {},
        userFriends: {},
      };
    }
    return state;
  },
  whitelist: [
    'users_auth',
    'branch_auth',
    'company_auth',
    'item_auth',
    'template_item_auth',
    'order_auth',
    'quest_auth',
    'mission_auth',
    'comment_auth',
    'tray',
    'perks',
    'powerUps',
    'powerUpPieces',
    'craveStore',
    'userBoughtFromUs',
    'company_creation'
  ],
};

const rootReducer = combineReducers({
  users_auth: users_slice,
  branch_auth: branchReducer,
  company_auth: company_slice,
  item_auth: item_slice,
  template_item_auth: template_item_slice,
  order_auth: orders_slice,
  comment_auth: comment_slice,
  quest_auth: quest_slice,
  mission_auth: mission_slice,
  tray: trayReducer,
  perks: perkReducer,
  powerUps: powerUps_slice,
  powerUpPieces: powerUpsPieces_slice,
  craveStore: crave_store_slice,
  userBoughtFromUs: userBoughtFromUs_slice,
  userFriends: user_friends_slice,
  company_creation: company_creation_slice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          'persist/PURGE',
          'persist/FLUSH',
          'persist/PAUSE',
          'persist/REGISTER',
        ],
        ignoredPaths: ['result'],
      },
    }),
});

export const persistor = persistStore(store);

export default store;
