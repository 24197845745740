import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import crave_store_service from './crave_store_service';
import toast from 'react-hot-toast';

const appVersion = '1.0.0' // Your current app version

const initialState = {
  items: [],
  categories: [],
  selectedCategory: 'All',
  selectedItem: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  appVersion, // Add app version to state
};

// Fetch Store Items
export const fetchStoreItems = createAsyncThunk(
  'craveStore/fetchStoreItems',
  async (_, thunkAPI) => {
    try {
      return await crave_store_service.getItems();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Purchase Item
export const purchaseItem = createAsyncThunk(
  'craveStore/purchaseItem',
  async ({ item, amount }, thunkAPI) => {
    try {
      return await crave_store_service.purchaseItem(item, amount);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const storeSlice = createSlice({
  name: 'craveStore',
  initialState,
  reducers: {
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchStoreItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items = action.payload.items;
        state.categories = action.payload.categories;
      })
      .addCase(fetchStoreItems.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(purchaseItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(purchaseItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success('Item purchased successfully!');
      })
      .addCase(purchaseItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});
export const { setSelectedCategory, setSelectedItem, reset } = storeSlice.actions;
export default storeSlice.reducer;
