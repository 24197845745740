import axios from 'axios'

const API_URL = 'http://localhost:5000/api/quests/'

// Register meeting
const registerMeeting = async (meetingData) => {
  const response = await axios.post(API_URL + 'register',meetingData)
  if (response.data) {
    console.log(response.data)
    // localStorage.setItem('meetingRegistered', JSON.stringify(response.data))
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
}
// Get All Companies
const getMeetings  = async (addNumber) => {
  console.log(addNumber)
  // const response = await axios.get(API_URL + 'get' , addNumber)
  const response = await axios({
    method: 'get',
    url: API_URL+'get',
    params: {addNumber: addNumber},
  })
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Get One meeting
const getOneMeeting  = async (comp_id) => {
  const response = await axios.get(API_URL + 'getOne' , comp_id)
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Update One meeting
const updateMeetingInfo  = async (compData) => {
  // const response = await axios.put(API_URL + 'update' , compData)
  const response = await axios({
    method: 'put',
    url: API_URL+'update',
    params: compData,
  })
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data
} 
// Delete One meeting
const deleteMeeting  = async (comp_id) => {
  const response = await axios.delete(API_URL + 'delete' , comp_id)
  if (response.data) {
    console.log(response.data)
  }else{
    console.log(error); console.log(error.message); console.log(error.code);
  }
  return response.data  
} 

const meetingAuthService = {
  registerMeeting,  
  getMeetings,  
  getOneMeeting,  
  updateMeetingInfo,  
  deleteMeeting,  
}
export default meetingAuthService
