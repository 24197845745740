import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaHome } from "react-icons/fa";
import styles from './UserNavs.module.css';
import { useSelector } from "react-redux";

const navVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1,
    },
  }),
  exit: { opacity: 0, y: -20 },
};

export const UserNavs = ({ activeCategory, handleTabClick, showCurrentNav }) => {
  return (
    <div className={styles.navContainer}>
      <div className={styles.navContent}>
        <AnimatePresence>
          {showCurrentNav === "Crave_Home" && (
            <motion.div
              key="Crave_Home"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={navVariants}
            >
              <SlideTabs activeCategory={activeCategory} handleTabClick={handleTabClick} />
            </motion.div>
          )}
          {showCurrentNav === "Company_Profile" && (
            <motion.div
              key="Company_Profile"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={navVariants}
            >
              <CompanyNav handleTabClick={handleTabClick} />
            </motion.div>
          )}
          {showCurrentNav === "User" && (
            <motion.div
              key="User"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={navVariants}
            >
              <UserNav handleTabClick={handleTabClick} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

const homeNavs = [{ name: "Me" }, { name: "Community" }, { name: "Places" }];
// const homeNavs = [{ name: "Me" }, { name: "Community" }, { name: "Places" }, { name: "Store" }];

const SlideTabs = ({ activeCategory, handleTabClick }) => {
  const categoryRefs = useRef({});
  const [position, setPosition] = useState({
    left: 0,
    width: 0,
    opacity: 0,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      const activeRef = categoryRefs.current[activeCategory];
      if (activeRef) {
        const { offsetLeft, offsetWidth } = activeRef;
        setPosition({
          left: offsetLeft,
          width: offsetWidth,
          opacity: 1,
        });
      }
    }, 100); // Adjust the delay to 30ms

    return () => clearTimeout(timer);
  }, [activeCategory]);

  return (
    <ul className={styles.tabList}>
      <AnimatePresence>
        {homeNavs.map((categ, i) => (
          <motion.li
            key={categ.name}
            initial="hidden"
            animate="visible"
            exit="exit"
            custom={i}
            variants={navVariants}
            ref={el => (categoryRefs.current[categ.name] = el)}
            className={`${styles.tabItem} ${activeCategory === categ.name ? styles.activeTab : ''}`}
            onClick={() => handleTabClick(categ.name)}
          >
            {categ.name}
          </motion.li>
        ))}
      </AnimatePresence>
      <Cursor position={position} />
    </ul>
  );
};

const Cursor = ({ position }) => {
  return (
    <motion.li
      animate={{
        left: position.left,
        width: position.width,
        opacity: position.opacity,
      }}
      transition={{ duration: 0.5, type: 'spring', stiffness: 300, damping: 30 }}
      className={styles.cursor}
    />
  );
};

const CompanyNav = ({ handleTabClick }) => {
  const { currentCompany } = useSelector((state) => state.company_auth);
  return (
    <div className="flex justify-between items-center w-full bg-slate-500">
      {/* <div className="flex items-center w-1/3">
        <button onClick={() => handleTabClick('Home')} className="text-gray-500">
          <FaHome className="text-2xl" />
        </button>
      </div> */}
      {/* <div className="flex justify-around items-center w-full">
        <div className="text-xl">Crave</div>
        <div className="text-xl">X</div>
        <div className="text-xl">{currentCompany.name}</div>
      </div> */}
    </div>
  );
};

const UserNav = ({ handleTabClick }) => {
  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex items-center w-1/3">
        <button onClick={() => handleTabClick('Home')} className="text-gray-500">
          <FaHome className="text-2xl" value={{ color: "black"}}/>
        </button>
      </div>
      {/* <div className="flex justify-around items-center w-2/3">
        <div className="text-xl">Crave</div>
        <div className="text-xl">X</div>
        <div className="text-xl">User</div>
      </div> */}
    </div>
  );
};
